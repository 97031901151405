import { Route, Redirect } from "react-router-dom";
import doesHttpOnlyCookieExist from "../component/utilities/doesHttpOnlyCookieExist";
import SearchContextProvider from "../context/search-context";

export const ProtectedRoute = ({ title, component: Component, ...rest }) => {
  document.title = title || "FYT";
  return (
    <Route
      {...rest}
      render={(props) => {
        return doesHttpOnlyCookieExist("authenticated") ? (
          <SearchContextProvider>
            <Component {...props} />
          </SearchContextProvider>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};
