import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  Typography,
  makeStyles,
  Grid,
  TextField,
  DialogTitle,
  Button,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useForm } from "react-hook-form";
import axios from "axios";
import { UserContext } from "../../../context/user-context";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyle = makeStyles((theme) => ({
  GridTemplate: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
  },
  typographyGrid: {
    marginBottom: "25px",
    textAlign: "left",
  },
  cricle: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    display: "inline-block",
    alignSelf: "center",
  },
  boxTitle: {
    backgroundColor: theme.palette.primary.main,
    padding: "0 20px",
    borderRadius: "50px",
    margin: "20px 0",
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
  },
  input: {
    height: "2em",
    fontSize: "1.2rem",
  },
  multiLineInput: {
    fontSize: "1.2rem",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PopupEmail(props) {
  const classes = useStyle();
  const { buttonOpen, setButtonOpen, email, founderId } = props;
  const { register, handleSubmit, errors } = useForm();
  const { user } = useContext(UserContext);
  // const [image, setImage] = useState();

  const handleClose = () => {
    setButtonOpen(false);
  };

  const onSubmit = async (data) => {
    data.founderId = founderId;
    data.investedBy = user.id;
    data.greeting = `Dear founder ${user.fullname}`;
    // console.log(data);
    try {
      await axios.post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/interest`,
        {
          ...data,
        },
        { withCredentials: true }
      );
      setButtonOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => handleClose()}
        aria-labelledby="customized-diamslog-title"
        open={buttonOpen}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography
            variant="h6"
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <EmailIcon style={{ paddingRight: "10px" }} />
            Send Emial
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ padding: "0px 30px 30px 30px" }}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="To"
                  name="email"
                  InputProps={{
                    classes: { input: classes.input },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6">Email :</Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.email ? true : false}
                  defaultValue={email}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Typography>Subject</Typography> */}
                <TextField
                  fullWidth
                  placeholder="Add Subject"
                  variant="standard"
                  name="subject"
                  InputProps={{
                    classes: { input: classes.input },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6">Subject :</Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.subject ? true : false}
                  defaultValue="New interest from investor"
                  // label="Add Subject"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  placeholder="Message"
                  rows={10}
                  variant="standard"
                  name="message"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.message ? true : false}
                  InputProps={{ classes: { input: classes.multiLineInput } }}
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                hidden
                accept="image/*, application/pdf"
                id="contained-button-file"
                multiple
                type="file"
                // onChange={(e) => setImage(e.target.files[0])}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{ marginTop: "20px" }}
                >
                  <AttachFileIcon />
                  Add Attachments
                </Button>
              </label>
              <Button
                // onClick={() => handleClose()}
                variant="outlined"
                color="primary"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
