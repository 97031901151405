import { makeStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useState, useMemo, useCallback, useContext } from "react";
import "date-fns";
import React from "react";
import Filter from "../filter";
import { Switch, Route, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AllGraduate from "./all-graduate";
import InvestableCompany from "./investable-company";
import TitleHeader from "../title-header";
import { SearchContext } from "../../context/search-context";

const useStyle = makeStyles((theme) => ({
  scroller: {
    "& > span": {
      maxWidth: 0,
    },
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },
}));

const Graduate = ({ match, location }) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();
  const type = ["accepted", "investableCompany"];
  const path = ["", "investableCompany"];

  const tabLabel = ["All Graduate", "Investable Company"];

  const handleChange = () => {
    // console.log(pathname);
    if (pathname === "/graduate") {
      setValue(path[0]);
    } else {
      path.map((value) => {
        if (pathname.includes(value)) {
          setValue(value);
        }
      });
    }
  };

  const { minDate, maxDate, investorTypes, investmentSector, search } =
    useContext(SearchContext);
  useMemo(() => handleChange(), [pathname]);
  return (
    <>
      <TitleHeader title="Graduate" />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ scroller: classes.scroller }}
      >
        {tabLabel.map((item, index) => (
          <Tab
            key={index}
            component={Link}
            to={
              location.search
                ? `${match.url}/${path[index]}?search=${search}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
                : path[index] === ""
                ? `${match.url}`
                : `${match.url}/${path[index]}`
            }
            disableRipple
            label={item}
            value={path[index]}
          />
        ))}
      </Tabs>
      <Filter />
      <div className="tabs">
        <Switch>
          <Route
            path={`${match.url}`}
            exact
            render={(props) => (
              <AllGraduate {...props} type={type[0]} moveTo={[type[1]]} />
            )}
          />
          <Route
            path={`${match.url}/${type[1]}`}
            exact
            render={(props) => (
              <InvestableCompany {...props} type={type[1]} moveTo={[]} />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default Graduate;
