import { useState, useEffect, useMemo, useCallback } from "react";
import "date-fns";
// Our own written code
import Card from "../../card";
import fetchData from "../../utilities/fetch-data";
import getSearchParams from "../../utilities/getSearchParams";
import PaginationRoute from "../../investor/pagination-route";
import Check from "../../utilities/check-isExist";
import checkPagination from "../../utilities/check-pagination";

const Rejected = (props) => {
  // Extract props
  const { match, location, type, history } = props;
  // Extract queryParam from url
  const { page, minDate, maxDate, search } = getSearchParams(location.search);
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/apply-for-fund`,
      status: `${type}`,
    });
    // console.log(dataFromApi);
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [maxDate, minDate, page, search, type]);
  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [location.search]);
  //Function go back to previous page if don't have any data
  useMemo(() => {
    checkPagination({
      history: history,
      data: data.founder,
      paginations: data.paginations,
    });
  }, [data.founder]);
  return (
    <>
      <Check
        data={data.founder ? true : false}
        children={
          data.founder &&
          data.founder.map((item) => (
            <Card founder key={item._id} popupFounder Rejected data={item} />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default Rejected;
