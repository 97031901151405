import axios from "axios";

const FetchAndCreatePermissionObj = async () => {
  // Obj to setState after converting data
  const currentUserPermission = {};
  const res = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/me`,
    { withCredentials: true }
  );
  // Fetch role and permission and the current user's role
  const roleAndPermission = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles/${res.data.user.roleName}`,
    {
      withCredentials: true,
    }
  );
  const { features,
    // roleName: currentUserRoleName
  } = roleAndPermission?.data
    ?.role || { features: [], roleName: "" };
  // Converting the Feature to be an Obj ( Easy for checking )
  await Promise.all(
    features?.map(async (feature) => {
      const { featureName, rolePermission } = feature;
      const helperObj = {};
      await Promise.all(
        rolePermission?.map((item) => {
          const { permissionName, boolean } = item;
          helperObj[permissionName] = boolean;
          if (featureName === "unit") {
          }
          return null
        })
      );
      currentUserPermission[featureName] = helperObj;
    })
  );
  return currentUserPermission;
};

export default FetchAndCreatePermissionObj;
