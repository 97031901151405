import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";

const ConFirmPopup = (props) => {
  const { open, setOpen, id, setRender } = props;

  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    // http://localhost:4000/api/v1/users/60af391c0ff835544c120796
    setOpen(false);
  };
  const handleDelete = () => {
    axios
      .patch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/users/${id}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => (
          console.log(res),
          setRender((pre) => !pre),
          enqueueSnackbar(`Delete Employee Successfully`, {
            variant: "success",
            TransitionComponent: Slide,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
        )
      )
      .catch((err) => {
        console.log("this is err", err);
        enqueueSnackbar(`Sorry something when wrong in the 46`, {
          variant: "error",
          TransitionComponent: Slide,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h5" color="primary">
            Confirm
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            Are you Sure you want to delete it ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => handleClick()} color="secondary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConFirmPopup;
