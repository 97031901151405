import { useContext } from "react";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { useHistory } from "react-router-dom";
import doesHttpOnlyCookieExist from "../utilities/doesHttpOnlyCookieExist";
import { useSnackbar } from "notistack";
import {
  FetchAndCreatePermissionObj,
  PermissionContext,
} from "../../context/permission-context";
import { UserContext } from "../../context/user-context";
// import IsLogin from "../utilities/useLogin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  containter: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    "-webkit-tap-highlight-color": "transparent",
    zIndex: "1201",
    display: "flex",
    overflow: "hidden",
  },
  imageBackGround: {
    position: "absolute",
    zIndex: -1,
    objectFit: "cover",
    height: "100%",
    width: "100%",
    opacity: 0.5,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const Login = (props) => {
  // MUI Classes
  const classes = useStyles();
  // Set permission to context state
  const { setPermission } = useContext(PermissionContext);
  // User context
  const { setUser } = useContext(UserContext);
  // Call Hook for Form
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  // Initialize Snackbar Hook
  const { enqueueSnackbar } = useSnackbar();
  // Handle Submit
  const onSubmit = async (userData) => {
    // Hit api login
    try {
      const test = await axios.post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/login`,
        { ...userData },
        { withCredentials: true }
      );
      // Check if we're authenticated
      if (doesHttpOnlyCookieExist("authenticated")) {
        // Hit api which return the data of that user
        const { data } = await axios.get(
          `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/me`,
          {
            withCredentials: true,
          }
        );
        // Set the data to user context state
        setUser(data.user);
        // Fetch the permissions of the current user
        const permissionFromApi = await FetchAndCreatePermissionObj();
        // Set the permission to permission context state
        setPermission(permissionFromApi);
        // Redirect to dashboard
        history.push("/");
      }
    } catch (err) {
      //when error open popup
      enqueueSnackbar(
        err.response.data
          ? `${err.response.data}`
          : `sorry something when wrong please try again`,
        {
          variant: "error",
          TransitionComponent: Slide,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
      console.log(err);
    }
  };
  return (
    <div className={classes.containter}>
      <img
        src="/back.jpg"
        className={classes.imageBackGround}
        alt="Background"
      />
      <Container component="main" maxWidth="sm">
        <Paper style={{ padding: "30px" }}>
          <Box className={classes.imageBox}>
            <img src="/logo.jpeg" alt="Logo" className={classes.image} />
          </Box>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: true,
              })}
              error={errors.email ? true : false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={register({
                required: true,
              })}
              error={errors.password ? true : false}
            />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Link href="/forget-password" variant="body2">
                Forgot password ?
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};
export default Login;
