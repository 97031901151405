import { Box, Typography, Paper, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const useStyle = makeStyles((theme) => ({
  paperStyle: {
    display: "flex",
    padding: "20px",
    cursor: "pointer",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  boxColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ItemBox = (props) => {
  const {
    item: { name, icon, role, IsGraduated },
  } = props;
  let history = useHistory();
  const classes = useStyle();
  const [total, setTotal] = useState(0);

  const handleClick = (item) => {
    history.push(`/${item}`);
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/total?countByIsGraduated=${IsGraduated}true&countByroleName=${role}`,
        { withCredentials: true }
      )
      .then((res) => setTotal(res.data.total))
      .catch((err) => console.log(err));
  });

  return (
    <Paper
      elevation={1}
      className={classes.paperStyle}
      onClick={() => handleClick(name)}
    >
      <Box
        className={`${classes.boxColor} center`}
        height="70px"
        width="70px"
        borderRadius="10px"
        marginRight="10px"
      >
        {icon}
      </Box>
      <Box flex={1}>
        <Typography variant="h3" style={{ fontWeight: "bold" }} color="primary">
          {total}
        </Typography>
        <Typography variant="h6" style={{ textTransform: "capitalize" }}>
          {name}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ItemBox;
