import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { useHistory } from "react-router-dom";
// Time Ago Package section
import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.4rem",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(245, 245, 245)",
      transition: "all 0.5s ease-out",
    },
  },
  icon: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: "0.35rem",
    borderRadius: "50%",
    fontSize: "1.8rem",
  },
  haveNotRead: {
    backgroundColor: "#599DE8",
    borderRadius: "50%",
    width: "13px",
    height: "13px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  haveReadBadge: {
    cursor: "pointer",
    backgroundColor: "transparent",
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    border: "1px solid #599DE8",
    "&:hover": {
      backgroundColor: "rgb(238, 238, 238)",
    },
  },
}));

const SingleNotification = ({
  _id,
  sender,
  messageData,
  isRead,
  isSeen,
  userId,
  title,
  createdAt,
  frontendMarkAllAsRead,
  setRender,
}) => {
  const history = useHistory();
  // MUI classes
  const classes = useStyles();
  // Handle frontend notification status ( For change style and make request to change in the background )
  const [frontendIsRead, setFrontendIsRead] = useState(isRead);
  // Generate duration from createdAt to now in each notification
  const createdAtDate = new Date(createdAt);
  const timeAgo = new TimeAgo("en-US");
  const durationToShow = timeAgo.format(createdAtDate);

  const updateIsReadOneNotification = async ({ id, isRead }) => {
    await axios.put(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/notification/update-one`,
      { id, isRead },
      { withCredentials: true }
    );
    setFrontendIsRead(isRead);
    setRender((pre) => !pre);
  };

  useEffect(() => {
    if (frontendMarkAllAsRead.helper > 0) {
      setFrontendIsRead(frontendMarkAllAsRead.isRead);
    }
  }, [frontendMarkAllAsRead]);
  //check to see which role registered then when click go to that role page
  const handleClick = () => {
    if (messageData.includes("investor")) {
      history.push(`/investor`);
    } else if (messageData.includes("founder")) {
      history.push(`/applicant`);
    }
  };
  return (
    <Grid
      item
      xs={12}
      onClick={() => handleClick()}
      container
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs="auto" style={{ marginRight: "0.6rem" }}>
        <EventAvailableIcon className={classes.icon} />
      </Grid>
      <Grid item xs={true}>
        <Typography variant="body1" align="left">
          <Typography
            component="span"
            style={{
              fontWeight: "bold",
              marginRight: "4px",
              display: "inline-block",
            }}
            align="left"
          >
            {title}
          </Typography>
          {messageData}
        </Typography>
        <Typography color="primary" style={{ fontWeight: "bold" }} align="left">
          {durationToShow}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        {frontendIsRead ? (
          <Box
            className={classes.haveReadBadge}
            onClick={() =>
              updateIsReadOneNotification({ id: _id, isRead: false })
            }
          ></Box>
        ) : (
          <Box
            className={classes.haveNotRead}
            onClick={() =>
              updateIsReadOneNotification({ id: _id, isRead: true })
            }
          ></Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleNotification;
