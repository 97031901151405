import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.3rem",
    fontWeight: "600",
  },
  body: {
    fontSize: "1rem",
    color: "rgb(97, 97, 97)",
    fontWeight: "normal",
  },
  btn: {
    fontSize: "0.75rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationPopCmp = ({
  headerText,
  bodyText,
  isOpen,
  onConfirmClick,
  onCancelClick,
  confirmBtnText,
  cancelBtnText,
}) => {
  // MUI Classes
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancelClick}
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.title}>
        {headerText}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className={classes.body}>
          {bodyText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelClick}
          color="secondary"
          className={classes.btn}
        >
          {cancelBtnText}
        </Button>
        <Button
          onClick={() => {
            onConfirmClick();
            onCancelClick(false);
          }}
          color="primary"
          className={classes.btn}
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopCmp;
