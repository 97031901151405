import { makeStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useState, useMemo, useContext, useCallback } from "react";
import "date-fns";
import React from "react";
import Filter from "../filter";
import { Switch, Route, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AllInvestor from "./all-investor";
import Screening from "./screening";
import Accepted from "./accepted";
import Rejected from "./rejected";
import TitleHeader from "../title-header";
import { SearchContext } from "../../context/search-context";
import { PermissionContext } from "../../context/permission-context";

const useStyle = makeStyles((theme) => ({
  scroller: {
    "& > span": {
      maxWidth: 0,
    },
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },
}));

const Investor = ({ data, match, location }) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);
  const [filterValue, setFilterValue] = useState({
    investmentSector: [],
    investorTypes: [],
  });
  const { permission } = useContext(PermissionContext);
  const { pathname } = useLocation();
  const type = ["all", "screening", "rejected", "accepted"];
  const path = useMemo(
    () => [
      ...(permission.investor && permission.investor.view ? [""] : []),
      ...(permission.investor && permission.investor.move_to_screening
        ? ["screening"]
        : []),
      ...(permission.investor && permission.investor.rejected
        ? ["rejected"]
        : []),
      ...(permission.investor && permission.investor.accepted
        ? ["accepted"]
        : []),
    ],
    [permission.investor],
  );
  const tabLabel = [
    ...(permission.investor && permission.investor.view
      ? ["All Investor"]
      : []),
    ...(permission.investor && permission.investor.move_to_screening
      ? ["Screening"]
      : []),
    ...(permission.investor && permission.investor.rejected
      ? ["Rejected"]
      : []),
    ...(permission.investor && permission.investor.accepted
      ? ["Accepted"]
      : []),
  ];
  const { minDate, maxDate, investorTypes, investmentSector, search } =
    useContext(SearchContext);

  const handleChange = useCallback(() => {
    if (pathname === "/investor") {
      setValue(path[0]);
    } else {
      path.forEach((value) => {
        if (pathname.includes(value)) {
          setValue(value);
        }
      });
    }
  }, [path, pathname]);
  useMemo(() => handleChange(), [pathname]);

  return (
    <>
      <TitleHeader title="Investor" />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ scroller: classes.scroller }}
      >
        {tabLabel.map((item, index) => (
          <Tab
            key={index}
            component={Link}
            to={
              location.search
                ? `${match.url}/${path[index]}?search=${search}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
                : path[index] === ""
                ? `${match.url}`
                : `${match.url}/${path[index]}`
            }
            disableRipple
            label={item}
            value={path[index]}
          />
        ))}
      </Tabs>
      <Filter
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        company
      />
      <div className="tabs">
        <Switch>
          <Route
            path={`${match.url}`}
            exact
            render={(props) => (
              <AllInvestor
                {...props}
                type={type[0]}
                moveTo={[type[1], type[2]]}
              />
            )}
          />
          {permission.investor && permission.investor.move_to_screening && (
            <Route
              path={`${match.url}/screening`}
              exact
              render={(props) => (
                <Screening
                  {...props}
                  type={type[1]}
                  moveTo={[type[2], type[3]]}
                />
              )}
            />
          )}
          {permission.investor && permission.investor.rejected && (
            <Route
              path={`${match.url}/rejected`}
              exact
              render={(props) => <Rejected {...props} type={type[2]} />}
            />
          )}

          {permission.investor && permission.investor.accepted && (
            <Route
              path={`${match.url}/accepted`}
              exact
              render={(props) => <Accepted {...props} type={type[3]} />}
            />
          )}
        </Switch>
      </div>
    </>
  );
};

export default Investor;
