import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Badge,
  makeStyles,
  Grid,
  Typography,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CheckIcon from "@material-ui/icons/Check";
// Firebase section
import firebase from "firebase/app";
import "firebase/database";
import config from "../../utilities/firebase-config";
// Notification List
import NotificationList from "./notification-list";
import doesHttpOnlyCookieExist from "../../../component/utilities/doesHttpOnlyCookieExist";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "10px",
  },
  notificationContainer: {
    position: "absolute",
    width: "350px",
    top: "4.7rem",
    left: "-100px",
    zIndex: 10000,
    backgroundColor: "white",
    padding: "0.3rem 0 0.6rem 0.6rem",
    "&:after": {
      content: '" "',
      position: "absolute",
      left: "115px",
      top: "-13px",
      borderTop: "none",
      borderRight: "15px solid transparent",
      borderLeft: "15px solid transparent",
      borderBottom: "15px solid white",
    },
  },
  markAllAsRead: {
    margin: "0.3rem 0 0.7rem 0",
    cursor: "pointer",
    padding: "0.2rem 0.5rem",
    transition: "all 0.3s ease-out",
    color: theme.palette.primary.main,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#edf2ff",
    },
  },
}));

const Notification = () => {
  // For navigation
  const history = useHistory();
  // MUI classes
  const classes = useStyles();
  // Handle showing notification
  const [showNotification, setShowNotification] = useState(false);
  // Means new unseen notification
  const [unseenAmount, setUnseenAmount] = useState(0);
  // Handle all notifications' data
  const [notificationData, setNotificationData] = useState([]);
  // Handle Pagination
  const [page, setPage] = useState(1);
  // Handle if we have any next page
  const [hasNextPage, setHasNextPage] = useState(false);
  // Handle Rerender
  const [render, setRender] = useState(false);
  // Change appearance on frontend when click markallasread
  const [frontendMarkAllAsRead, setFrontendMarkAllAsRead] = useState({
    helper: 0,
    isRead: false,
  });

  const connect = async (database) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/me`,
        { withCredentials: true }
      );
      await database
        .ref(`${data.user.id}/notifications`)
        .on("child_added", (snapshot) => {
          const newNotification = snapshot.val();
          if (newNotification.data) {
            setUnseenAmount((prev) => prev + 1);
            setNotificationData((prev) => [
              JSON.parse(newNotification.data),
              ...prev,
            ]);
          }
        });
    } catch (e) {
      console.error(e);
    }
  };
  const fetchData = useCallback(
    async ({ page }) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/notification?page=${page}&perPage=10`,
        { withCredentials: true }
      );
      const {
        hasNextPage,
        newNotificationCount,
        notification,
        // hasPrevPage,
        // page: pageFromApi,
        // pages,
        // totalDocs,
      } = data;
      if (!doesHttpOnlyCookieExist("authenticated")) {
        setShowNotification(false);
        history.replace("/login");
      } else {
        setHasNextPage(hasNextPage);
        setUnseenAmount(newNotificationCount);
        setNotificationData((prev) => notification);
      }
    },
    [history.location, render]
  );

  // Handle when user click the bell icon
  const onClickTheBell = async () => {
    // Toggle to show notification
    setShowNotification((prev) => !prev);
    // If there's no new notification we don't need to make request to api
    if (unseenAmount) {
      await axios.put(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/notification?isSeen=true`,
        {},
        { withCredentials: true }
      );
      setUnseenAmount(0);
    }
  };

  const handleMarkAllAsRead = async () => {
    await axios.put(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/notification?isRead=true`,
      {},
      { withCredentials: true }
    );
    // Update View
    setFrontendMarkAllAsRead({
      helper: frontendMarkAllAsRead.helper + 1,
      isRead: true,
    });
  };

  useEffect(() => {
    // Fetch notification from db
    fetchData({ page });
  }, [fetchData, page]);

  // Initialize Firebase RealTime DB
  useEffect(() => {
    firebase.initializeApp(config);
    connect(firebase.database());
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setShowNotification(false)}>
      <Paper className={classes.root}>
        <Badge
          color="primary"
          badgeContent={unseenAmount}
          onClick={onClickTheBell}
          style={{ cursor: "pointer" }}
        >
          <NotificationsNoneOutlinedIcon fontSize="large" />
        </Badge>
        {showNotification ? (
          <Paper elevation={3} className={classes.notificationContainer}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={true}>
                    <Typography
                      align="left"
                      variant="h6"
                      color="primary"
                      style={{ marginBottom: "0.5rem", fontWeight: "bold" }}
                    >
                      Notification
                    </Typography>
                  </Grid>
                  <Grid item xs="auto" style={{ paddingRight: "0.6rem" }}>
                    <Grid
                      container
                      className={classes.markAllAsRead}
                      alignItems="center"
                      justify="flex-end"
                      onClick={handleMarkAllAsRead}
                    >
                      <CheckIcon style={{ marginRight: "0.3rem" }} /> Mark all
                      as read
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* All notifications goes here */}
              <NotificationList
                notificationData={notificationData}
                hasNextPage={hasNextPage}
                setPage={setPage}
                frontendMarkAllAsRead={frontendMarkAllAsRead}
                setRender={setRender}
              />
            </Grid>
          </Paper>
        ) : (
          <></>
        )}
      </Paper>
    </ClickAwayListener>
  );
};

export default Notification;
