import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "../pop-up";

// import IsLogin from "../utilities/useLogin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imageBox: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  containter: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    "-webkit-tap-highlight-color": "transparent",
    zIndex: "1201",
    display: "flex",
  },
  image: {
    position: "absolute",
    zIndex: -1,
    objectFit: "cover",
    height: "100%",
    width: "100%",
    opacity: 0.5,
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMassage] = useState("");
  const { register, handleSubmit, errors, getValues } = useForm();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const onSubmitReset = (data) => {
    console.log(token, { newPassword: data.newPassword });
    axios
      .post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/reset-password`,
        { newPassword: data.newPassword, token },
        { withCredentials: true }
      )
      .then((res) => {
        setOpen(true);
        setError(false);
        setMassage("Please use your new password to login");
        // console.log("forget password", res);
        // setReset(true);
      })
      .catch((err) => {
        setOpen(true);
        setError(true);
        setMassage("Sorry something when wrong");
        console.log(err);
      });
  };
  return (
    <div className={classes.containter}>
      <img src="/back.jpg" className={classes.image} alt="Background" />
      <Container component="main" maxWidth="sm">
        <Popup open={open} setOpen={setOpen} error={error} message={message} />
        <Paper style={{ padding: "30px" }}>
          <Box width="100%" height="250px" className={classes.imageBox}>
            <img src="/logo.jpeg" alt="Logo" />
          </Box>
          <Typography variant="h5">Reset your password</Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmitReset)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="Enter a new password"
              name="newPassword"
              type="password"
              autoFocus
              inputRef={register({
                required: true,
              })}
              error={errors.newPassword ? true : false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm your new password"
              type="password"
              inputRef={register({
                required: true,
                validate: (value) => value === getValues("newPassword"),
              })}
              error={errors.confirmPassword ? true : false}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              SEND
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};
export default ResetPassword;
