import { useState, useEffect, useMemo, useCallback } from "react";
import "date-fns";
// Our own written code
import Card from "../../card";
import fetchData from "../../utilities/fetch-data";
import PaginationRoute from "../pagination-route";
import getSearchParams from "../../utilities/getSearchParams";
import Check from "../../utilities/check-isExist";
import checkPagination from "../../utilities/check-pagination";

const Accepted = (props) => {
  // Extract props
  const { match, location, type, moveTo, history } = props;
  // Extract queryParam from url
  const { page, minDate, maxDate, search, investorTypes, investmentSector } =
    getSearchParams(location.search);
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url and call axios for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
        investorTypes,
        investmentSector,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/investor`,
      status: `${type}`,
    });
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [investmentSector, investorTypes, maxDate, minDate, page, search, type]);
  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [location.search]);
  //Function go back to previous page if don't have any data
  useMemo(() => {
    checkPagination({
      history: history,
      data: data.investors,
      paginations: data.paginations,
    });
  }, [data.investors]);
  return (
    <>
      <Check
        data={data.investors ? true : false}
        children={
          data.investors &&
          data.investors.map((item) => (
            <Card
              key={item._id}
              Accepted
              data={item}
              popupInvestor
              popup
              buttonClickPopup
            />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default Accepted;
