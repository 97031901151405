const config = {
  apiKey: "AIzaSyC5fjkxkEelvql3vDIz-n6nY-OSBWdRvX0",
  authDomain: "fyt-dashboard.firebaseapp.com",
  projectId: "fyt-dashboard",
  storageBucket: "fyt-dashboard.appspot.com",
  messagingSenderId: "479859179290",
  appId: "1:479859179290:web:a2ef3539b78169a17736a3",
  measurementId: "G-P183B8948M",
};

export default config;
