import { cloneElement } from "react";
import { useInView } from "react-intersection-observer";
import { fadeInUp } from "react-animations";
import { StyleSheet, css } from "aphrodite";

// ** How to Use ** ( HOC )
// (1) Choose {animationName} in Combo Box in : http://react-animations.herokuapp.com/
// (2) Then import {animationName} from "react-animations" in children file
//     ex : import { bounce } from "react-animations";

// ** Note ** :
//  >> Passing Props Section
//  This HOC already have default settings, In case You don't pass any Props
//  This HOC receives ex : animationStyle={ bounce } , delay=1 , triggerOnce={true} , threshold=1 , duration=1 )
//  >> Opacity Section
//  Opacity that define in props style in children will take effect only after animation end
//  Because it starts with opacity 0 if children is not in user's view
//  But now we can't remove children by using display:none yet can only hide by opacity

const Animation = ({
  children,
  animationStyle = fadeInUp,
  delay = 0,
  triggerOnce = true,
  threshold = 1,
  duration = 1,
}) => {
  const { ref } = useInView({
    triggerOnce: triggerOnce,
    threshold: threshold,
  });

  const styles = StyleSheet.create({
    animation: {
      animationName: animationStyle,
      animationDuration: `${duration}s`,
      animationDelay: `${delay}s`,
    },
  });

  return cloneElement(children, {
    ref,
    className: true
      ? css(styles.animation) + " " + children.props.className
      : children.props.className,
    style: {
      opacity: true ? 1 : 0,
      ...children.props.style,
    },
  });
};

export default Animation;
