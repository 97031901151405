const getSearchParams = (searchUrl) => {
  const searchParams = new URLSearchParams(searchUrl);
  const page = parseInt(searchParams.get("page") || "1", 10);
  const perPage = parseInt(searchParams.get("perPage") || "1", 10);
  const minDate = searchParams.get("minDate") || "";
  const maxDate = searchParams.get("maxDate") || "";
  const search = searchParams.get("search") || "";
  const investmentSector = searchParams.get("investmentSector") || "";
  const investorTypes = searchParams.get("investorTypes") || "";
  const companyCategory = searchParams.get("companyCategory") || "";
  const isGraduated = searchParams.get("isGraduated") || "";
  return {
    page,
    perPage,
    minDate,
    maxDate,
    search,
    investmentSector,
    investorTypes,
    companyCategory,
    isGraduated,
  };
};

export default getSearchParams;
