import { createContext, useState } from "react";

export const NoteContext = createContext({
  value: "",
  setValue: () => {},
});

const NoteContextProvider = ({ children }) => {
  const [value, setValue] = useState("");

  return (
    <NoteContext.Provider
      value={{
        value,
        setValue,
      }}
    >
      {children}
    </NoteContext.Provider>
  );
};

export default NoteContextProvider;
