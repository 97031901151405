import CircularProgress from "@material-ui/core/CircularProgress";
import Animation from "../../../HOC/animation";

const Check = ({ children, data }) => {

  return (
    <>
      {data ? (
        children.length > 0 ? (
          <Animation duration={0.5}>
            <div>{children.map((item, index) => item)}</div>
          </Animation>
        ) : (
          <Animation duration={0.5}>
            <p style={{ fontSize: "3rem" }}>No Results</p>
          </Animation>
        )
      ) : (
        <div
          style={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
    </>
  );
};

export default Check;
