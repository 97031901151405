import { Redirect, Route } from "react-router-dom";
import doesHttpOnlyCookieExist from "../component/utilities/doesHttpOnlyCookieExist";

const LoginRoute = ({ component: Component, redirect, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return doesHttpOnlyCookieExist("authenticated") ? (
          <Redirect to={redirect} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default LoginRoute;
