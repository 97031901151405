import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/user-context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  profileStyle: {
    padding: "10px 20px",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogOut = (event) => {
    handleClose(event);
    axios
      .get(`${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/logout`, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res);
        if (res) {
          history.push("/login");
        }
      });
    // .then((e) => {
    //   console.log(e);
    //   history.push("/login");
    // });
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const { user } = useContext(UserContext);
  return (
    <div className={classes.root}>
      <Paper style={{ borderRadius: "10px", marginLeft: "10px" }}>
        <Button
          style={{ backgroundColor: "transparent" }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          disableRipple
          className={classes.profileStyle}
        >
          <Avatar
            style={{
              marginRight: 10,
              boxShadow: "2px 3px 4px 0px rgb(0 0 0 / 7%)",
            }}
            src={user && user.avatarUrl && user.avatarUrl}
          >
            {user && user.fullname && user.fullname.slice(0, 1)}
          </Avatar>
          <Typography style={{ textTransform: "capitalize" }}>
            {user.fullname}
          </Typography>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: "100" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{ minWidth: "150px" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Paper>
    </div>
  );
};

export default Profile;
