import "date-fns";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import CreateNew from "./create-new";
import AllEmployee from "./all-employee";
import { useContext } from "react";
import { PermissionContext } from "../../context/permission-context";
import getSearchParams from "../utilities/getSearchParams";

const Employee = ({ match, location }) => {
  const [employees, setEmployees] = useState([]);
  const [render, setRender] = useState(false);
  const [getData, setGetData] = useState({});
  const [roleData, setRoleData] = useState({});
  const [filter, setFilter] = useState({ page: 1, totalPages: 1 });
  const { permission } = useContext(PermissionContext);
  const perPaper = 12;
  const { page } = getSearchParams(location.search);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/users?perPage=${perPaper}&page=${page}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setEmployees(res.data.employees);
        setFilter((pre) => ({
          page: page,
          totalPages: res.data.paginations.totalPages,
        }));
      })
      .catch((err) => console.log(err));
    // console.log("use");
  }, [render, match]);

  // Fetch and update state to refresh the view
  const fetchAndUpdateState = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles`,
      { withCredentials: true }
    );
    if (data.role) {
      setRoleData(
        // filter roleName beside founder, investor, inestor-founder
        data.role
          .map((item) => item.roleName)
          .filter(
            (item) =>
              item !== "founder" &&
              item !== "investor" &&
              item != "investor-founder"
          )
      );
    }
  };
  useEffect(() => fetchAndUpdateState(), []);
  return (
    <>
      <Switch>
        <Route
          path={`${match.url}/`}
          exact
          render={(props) => (
            <AllEmployee
              {...props}
              employees={employees}
              setRender={setRender}
              setGetData={setGetData}
              filter={filter}
              setFilter={setFilter}
            />
          )}
        />
        {permission.registration && permission.registration.create && (
          <Route
            path={`${match.url}/new-employee`}
            exact
            render={(props) => (
              <CreateNew
                {...props}
                getData
                setRender={setRender}
                roleData={roleData}
              />
            )}
          />
        )}
        {permission.registration && permission.registration.update && (
          <Route
            path={`${match.url}/:id`}
            exact
            render={(props) => (
              <CreateNew
                {...props}
                getData={getData}
                setRender={setRender}
                roleData={roleData}
                update
              />
            )}
          />
        )}
      </Switch>
    </>
  );
};

export default Employee;
