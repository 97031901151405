import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import "date-fns";
import Slide from "@material-ui/core/Slide";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
// Our own written code
import Card from "../../card";
import fetchData from "../../utilities/fetch-data";
import PaginationRoute from "../../investor/pagination-route";
import getSearchParams from "../../utilities/getSearchParams";
import Check from "../../utilities/check-isExist";
import { PermissionContext } from "../../../context/permission-context";
import checkPagination from "../../utilities/check-pagination";

const Accepted = (props) => {
  // Extract props
  const { match, location, type, moveTo, history } = props;
  // Get the current user permission
  const { permission } = useContext(PermissionContext);
  // Initialize Snackbar Hook
  const { enqueueSnackbar } = useSnackbar();
  // Extract queryParam from url
  const { page, minDate, maxDate, search } = getSearchParams(location.search);
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/apply-for-fund`,
      status: `${type}`,
    });
    // console.log(dataFromApi);
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [maxDate, minDate, page, search, type]);

  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [location.search]);
  // Function handle when click promote
  const handleClick = async (id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/apply-for-fund/${id}`,
        {
          isGraduated: true,
        },
        { withCredentials: true }
      );
      await fetchAndUpdateView();
      enqueueSnackbar(`Move to Graduate success`, {
        variant: "success",
        TransitionComponent: Slide,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (error) {
      enqueueSnackbar(`Sorry something when wrong`, {
        variant: "error",
        TransitionComponent: Slide,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      console.log(error);
    }
  };
  //Function go back to previous page if don't have any data
  useMemo(() => {
    checkPagination({
      history: history,
      data: data.founder,
      paginations: data.paginations,
    });
  }, [data.founder]);
  return (
    <>
      <Check
        data={data.founder ? true : false}
        children={
          data.founder &&
          data.founder.map((item) => (
            <Card
              key={item._id}
              Accepted
              data={item}
              founder
              button={
                permission.applicant &&
                permission.applicant.promote &&
                item.about &&
                !item.about.isGraduated
                  ? "Promote"
                  : null
              }
              buttonIcon={<SchoolRoundedIcon />}
              onButtonClickAccepted={(e) => {
                handleClick(item._id);
              }}
              popupFounder
            />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default Accepted;
