import { Grid, makeStyles } from "@material-ui/core";
import ItemBox from "./item-box";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BusinessIcon from "@material-ui/icons/Business";

// Test access controll
import { useContext } from "react";
import { PermissionContext } from "../../context/permission-context";
// import ManageUIbyRole from "../../HOC/manage-ui-by-role";
import TitleHeader from "../title-header";

const useStyle = makeStyles((theme) => ({
  paperStyle: {
    display: "flex",
    padding: "30px 20px",
  },
  icon: { color: "white" },
}));

const Dashboard = () => {
  const classes = useStyle();
  const { permission } = useContext(PermissionContext);
  const itemBox = [
    ...(permission.investor && permission.investor.view
      ? [
        {
          name: "investor",
          role: "investor",
          IsGraduated: "",
          icon: <BusinessIcon fontSize="large" className={classes.icon} />,
        },
      ]
      : []),
    ...(permission.applicant && permission.applicant.view
      ? [
        {
          name: "applicant",
          role: "founder",
          IsGraduated: "",
          icon: (
            <SupervisorAccountIcon
              fontSize="large"
              className={classes.icon}
            />
          ),
        },
      ]
      : []),
    ...(permission.graduate && permission.graduate.view
      ? [
        {
          name: "graduate",
          role: "",
          IsGraduated: true,
          icon: (
            <SchoolRoundedIcon fontSize="large" className={classes.icon} />
          ),
        },
      ]
      : []),
  ];

  // Testing access controll

  return (
    <>
      {/* Testing access controll */}
      {/* <ManageUIbyRole feature="registration" action="view">
        <h1>Phearab</h1>
      </ManageUIbyRole> */}
      <TitleHeader title="Dashboard" />
      <Grid container spacing={2}>
        {itemBox.map((item) => {
          return (
            <Grid xs={4} item key={item.name}>
              <ItemBox item={item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Dashboard;
