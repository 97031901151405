import { createContext, useState, useEffect, useCallback } from "react";
import FetchAndCreatePermissionObj from "./fetch-and-set-permission";
import doesHttpOnlyCookieExist from "../../component/utilities/doesHttpOnlyCookieExist";

export const PermissionContext = createContext({
  permission: {},
  setPermission: () => {},
});

const PermissionContextProvider = ({ children }) => {
  const [permission, setPermission] = useState({});
  // When First Mount fetch all roles and its permission
  // Also fetch the current user role to generate permission accordingly

  const fetchDataAndSetState = useCallback(async () => {
    const permissionFromApi = await FetchAndCreatePermissionObj();
    setPermission(permissionFromApi);
  }, []);

  useEffect(() => {
    if (doesHttpOnlyCookieExist("authenticated")) {
      fetchDataAndSetState();
    }
  }, [fetchDataAndSetState]);

  return (
    <PermissionContext.Provider value={{ permission, setPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export { PermissionContextProvider, FetchAndCreatePermissionObj };
