// import logo from "./logo.svg";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import "./App.css";
import SideBar from "./component/side-bar";
import Dashboard from "./component/dashboard";
import Founder from "./component/founder";
import Graduate from "./component/graduate";
import Investor from "./component/investor";
import Employee from "./component/employee";
import ProfileHeader from "./component/profile-header";
import Login from "./component/login";
import Theme from "./component/theme";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./component/protectedRoute";
import LoginRoute from "./component/loginRoute";
import Setting from "./component/setting";
import ForgetPassword from "./component/login/forget-password";
import ResetPassword from "./component/login/reset-password";
// import SearchContextProvider from "./context/search-context";
import { useContext } from "react";
import { PermissionContext } from "./context/permission-context";
import doesHttpOnlyCookieExist from "./component/utilities/doesHttpOnlyCookieExist";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
}));
function App() {
  const classes = useStyle();
  const { permission } = useContext(PermissionContext);

  return (
    <Router>
      <ThemeProvider theme={Theme}>
        <div className={`App ${classes.container}`}>
          <SideBar />
          <div style={{ flex: "auto", margin: "20px" }}>
            <ProfileHeader />
            <Switch>
              {/* <Route path="/login" exact component={Login} />
              <Route path="/" exact component={Dashboard} />
              <Route path="/forget-password" exact component={ForgetPassword} />
              <Route path="/setting" exact component={Setting} />
              <Route path="/reset-password" exact component={ResetPassword} />
              <Route path="/investor" component={Investor} />
              <Route path="/applicant" component={Founder} />
              <Route path="/graduate" component={Graduate} />
              <Route path="/setting" exact component={Setting} />
              <Route path="/employee" component={Employee} /> */}
              {/* <Redirect from="*" to="/" /> */}
              {/* <Route path="*" exact={true} component={Dashboard} /> */}
              <LoginRoute path="/login" exact component={Login} redirect="/" />
              <LoginRoute
                path="/forget-password"
                exact
                component={ForgetPassword}
                redirect="/login"
              />
              <LoginRoute
                path="/reset-password"
                exact
                component={ResetPassword}
                redirect="/login"
              />
              <ProtectedRoute
                path="/"
                exact
                component={Dashboard}
                title="Dashboard"
              />
              {permission.investor && permission.investor.view && (
                <ProtectedRoute
                  path="/investor"
                  component={Investor}
                  title="Investor"
                />
              )}
              {permission.applicant && permission.applicant.view && (
                <ProtectedRoute
                  path="/applicant"
                  component={Founder}
                  title="Applicant"
                />
              )}
              {permission.graduate && permission.graduate.view && (
                <ProtectedRoute
                  path="/graduate"
                  component={Graduate}
                  title="Graduate"
                />
              )}
              {permission.registration && permission.registration.view && (
                <ProtectedRoute
                  path="/employee"
                  component={Employee}
                  title="Employee"
                />
              )}
              {permission.role && permission.role.view && (
                <ProtectedRoute
                  path="/setting"
                  exact
                  component={Setting}
                  title="Setting"
                />
              )}
              {doesHttpOnlyCookieExist("authenticated") ? (
                permission.role && <Redirect from="*" to="/" />
              ) : (
                <Redirect from="*" to="/login" />
              )}
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
