import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { NoteContext } from "../../../context/note-context";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PopupInvestor(props) {
  const {
    buttonOpen,
    setButtonOpen,
    buttonClick,
    onButtonClickAccepted,
    onButtonClickRejected,
  } = props;
  const { value, setValue } = useContext(NoteContext);
  const handleSave = () => {
    setButtonOpen(false);
    setValue("");
    if (buttonClick) {
      onButtonClickAccepted();
    } else {
      onButtonClickRejected();
    }
    // buttonClick();
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        // onClose={() => handleClose()}
        aria-labelledby="customized-diamslog-title"
        open={buttonOpen}
      >
        <DialogContent dividers style={{ padding: "30px" }}>
          <Grid container>
            <Grid item xs>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Note:
              </Typography>
              <TextField
                value={value}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: "end" }}>
            <Button
              onClick={() => {
                setButtonOpen(false);
                setValue("");
              }}
              variant="contained"
              color="secondary"
              style={{ marginTop: "20px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSave()}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px", marginLeft: "10px" }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
