import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Typography, Grid, Button } from "@material-ui/core";
import CardDecription from "../card-decription";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PopupInvestor(props) {
  const { card, open, setOpen, data } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose()}
        aria-labelledby="customized-diamslog-title"
        open={open}
      >
        <DialogContent dividers style={{ padding: "30px" }}>
          <Grid container>
            {card}
            {data.investorStatus &&
              data.investorStatus[data.investorStatus.length - 1].note &&
              data.investorStatus[data.investorStatus.length - 1].note.length >
                0 && (
                <Grid item xs>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                  >
                    Note:
                  </Typography>
                  <Typography>
                    {data.investorStatus[data.investorStatus.length - 1].note}
                  </Typography>
                </Grid>
              )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
