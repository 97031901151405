/* eslint-disable react/jsx-no-duplicate-props */
import { Paper } from "@material-ui/core";
import { useState } from "react";
import PopupFounder from "./pop-up-founder";
import PopupInvestor from "./pop-up-investor";
import Popup from "./pop-up";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import CardDecription from "./card-decription";
import PopupEmail from "./pop-up-email-form";

const Card = ({
  founder,
  Accepted,
  Rejected,
  pending,
  acceptButton,
  popupFounder,
  popupInvestor,
  popupEmail,
  button = "",
  popup,
  buttonIcon = <ThumbUpAltOutlinedIcon />,
  data,
  onButtonClickAccepted = () => null,
  onButtonClickRejected = () => null,
  buttonClickPopup,
  rejectedButton,
  rejectedButtonIcon,
}) => {
  const [open, setOpen] = useState(false);
  const [buttonOpen, setButtonOpen] = useState(false);
  const [buttonClick, setButtonClick] = useState(true);

  const card = (
    <CardDecription
      setOpen={setOpen}
      data={data}
      acceptButton={acceptButton}
      Accepted={Accepted}
      Rejected={Rejected}
      pending={pending}
      acceptButton={acceptButton}
      button={button}
      buttonIcon={buttonIcon}
      founder={founder}
      onButtonClickAccepted={onButtonClickAccepted}
      onButtonClickRejected={onButtonClickRejected}
      buttonClickPopup={buttonClickPopup}
      buttonOpen={buttonOpen}
      setButtonOpen={setButtonOpen}
      popupEmail={popupEmail}
      buttonClick={buttonClick}
      setButtonClick={setButtonClick}
      rejectedButton={rejectedButton}
      rejectedButtonIcon={rejectedButtonIcon}
    />
  );
  return (
    <>
      <Paper
        style={{
          padding: "20px 30px",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        {card}
      </Paper>
      {popupFounder && (
        <PopupFounder card={card} open={open} setOpen={setOpen} data={data} />
      )}
      {popupInvestor && (
        <PopupInvestor card={card} data={data} open={open} setOpen={setOpen} />
      )}
      {popup && (
        <Popup
          open={open}
          setOpen={setOpen}
          buttonOpen={buttonOpen}
          setButtonOpen={setButtonOpen}
          email={data && data.email}
          founderId={data._id}
          buttonClick={buttonClick}
          setButtonClick={setButtonClick}
          onButtonClickAccepted={onButtonClickAccepted}
          onButtonClickRejected={onButtonClickRejected}
        />
      )}
      {popupEmail && (
        <PopupEmail
          open={open}
          setOpen={setOpen}
          buttonOpen={buttonOpen}
          setButtonOpen={setButtonOpen}
          email={data && data.email}
          founderId={data._id}
          buttonClick={buttonClick}
          setButtonClick={setButtonClick}
          onButtonClickAccepted={onButtonClickAccepted}
          onButtonClickRejected={onButtonClickRejected}
        />
      )}
    </>
  );
};

export default Card;
