import {
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import { withRouter, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import { useMemo, useState, useCallback } from "react";
import { useContext } from "react";
import { PermissionContext } from "../../../context/permission-context";

const useStyle = makeStyles((theme) => ({
  button: {
    borderRadius: "5px",
    // marginLeft: "20px",
    // paddingLeft: "20px",
  },
  linkStyle: {
    textDecoration: "none",
    color: "black",
  },
  bgColor: {
    backgroundColor: "blue",
  },
  listItem: {
    borderRadius: "5px",
    margin: "10px 0",
  },
  listItemButton: {
    "&$selected": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    "&$selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  selected: {},
}));

const ListItemSideBar = (props) => {
  const classes = useStyle();
  const { history, match } = props;
  const { pathname } = useLocation();
  const [selected, setSelected] = useState("");
  const { permission } = useContext(PermissionContext);
  const nameList = [
    "/",
    "investor",
    "applicant",
    "graduate",
    "employee",
    "setting",
  ];
  const nameAarry = [
    "/",
    ...(permission.investor && permission.investor.view ? ["investor"] : []),
    ...(permission.applicant && permission.applicant.view ? ["applicant"] : []),
    ...(permission.graduate && permission.graduate.view ? ["graduate"] : []),
    ...(permission.registration && permission.registration.view
      ? ["employee"]
      : []),
    ...(permission.role && permission.role.view ? ["setting"] : []),
  ];

  const handleChange = useCallback(() => {
    nameList.forEach((value, index) => {
      if (pathname.includes(value)) {
        setSelected(value);
      }
    });
  }, [nameList, pathname]);
  useMemo(() => handleChange(), [pathname]);
  const listItem = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      onClick: () => history.push("/"),
    },
    ...(permission.investor && permission.investor.view
      ? [
          {
            name: "Investor",
            icon: <BusinessIcon />,
            onClick: () => history.push(`${match.url}investor`),
          },
        ]
      : []),
    ...(permission.applicant && permission.applicant.view
      ? [
          {
            name: "Applicant",
            icon: <SupervisorAccountIcon />,
            onClick: () => history.push(`${match.url}applicant`),
          },
        ]
      : []),
    ...(permission.graduate && permission.graduate.view
      ? [
          {
            name: "Graduate",
            icon: <SchoolRoundedIcon />,
            onClick: () => history.push(`${match.url}graduate`),
          },
        ]
      : []),
    ...(permission.registration && permission.registration.view
      ? [
          {
            name: "Employee",
            icon: <PersonOutlineIcon />,
            onClick: () => history.push(`${match.url}employee`),
          },
        ]
      : []),
    ...(permission.role && permission.role.view
      ? [
          {
            name: "Setting",
            icon: <SettingsIcon />,
            onClick: () => history.push(`${match.url}setting`),
          },
        ]
      : []),
  ];

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <List style={{ width: "95%" }}>
          {listItem.map((item, index) => {
            const { name, icon, onClick } = item;
            return (
              <ListItem
                className={classes.listItem}
                classes={{
                  root: classes.listItemButton,
                  selected: classes.selected,
                }}
                button
                key={name}
                onClick={onClick}
                selected={selected === nameAarry[index]}
              >
                {icon && (
                  <ListItemIcon style={{ color: "inherit" }}>
                    {icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={name} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </>
  );
};

export default withRouter(ListItemSideBar);
