import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmPopup from "./confirm-pop-up";
import { useContext } from "react";
import { PermissionContext } from "../../../context/permission-context";

const useStyle = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    boxShadow: "2px 3px 4px 0px rgb(0 0 0 / 7%)",
  },
  iconStyle: {
    marginRight: "7px",
  },
  iconButtonStyle: {
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  paddingChildren: {
    padding: "1px 0",
  },
}));

const EmployeeCard = (props) => {
  const { data, setRender, setGetData } = props;
  const classes = useStyle();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const { permission } = useContext(PermissionContext);
  const handleEdit = (id) => {
    setGetData(data);
    history.push(`${history.location.pathname}/${id}`);
  };
  return (
    <Paper style={{ padding: "0 20px 40px 20px", borderRadius: "20px" }}>
      <ConfirmPopup
        open={open}
        setOpen={setOpen}
        setRender={setRender}
        id={data._id}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "end", padding: 0 }}>
          {permission.registration && permission.registration.update && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(data._id)}
                className={classes.iconButtonStyle}
                disableRipple
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {permission.registration && permission.registration.delete && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleOpen()}
                disableRipple
                className={classes.iconButtonStyle}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={
            (permission.registration && permission.registration.delete) ||
            permission.registration.update
              ? { paddingTop: 0 }
              : { paddingTop: 30 }
          }
        >
          <Grid item style={{ paddingRight: "10px", paddingTop: "7px" }}>
            <Avatar
              src={data.avatarUrl}
              className={classes.avatar}
              sizes="large"
            />
          </Grid>
          <Grid
            item
            xs
            container
            style={{ textAlign: "start", overflow: "hidden" }}
          >
            <Typography
              variant="h6"
              noWrap
              className={classes.paddingChildren}
              style={{ textTransform: "capitalize", width: "100%" }}
            >
              {data.fullname} <br />
            </Typography>
            <Typography
              noWrap
              className={classes.paddingChildren}
              style={{ textTransform: "capitalize", width: "100%" }}
            >
              {data.roleName}
            </Typography>
            <Link
              href={`tel:${data.phone}`}
              style={{ display: "flex", overflow: "hidden", width: "100%" }}
            >
              <PhoneIcon color="primary" className={classes.iconStyle} />
              <Typography
                noWrap
                style={{ fontSize: "1rem", width: "100%" }}
                className={classes.paddingChildren}
              >
                {data.phone}
              </Typography>
            </Link>
            <div style={{ display: "flex", width: "100%" }}>
              <Link
                href={`mailto:${data.email}`}
                style={{ display: "flex", overflow: "hidden" }}
              >
                <EmailIcon color="primary" className={classes.iconStyle} />
                <Typography
                  noWrap
                  style={{ fontSize: "1rem" }}
                  className={classes.paddingChildren}
                >
                  {data.email}
                </Typography>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeCard;
