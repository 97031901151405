import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { useSnackbar } from "notistack";
import "date-fns";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
// Our own written code
import Card from "../../card";
import PaginationRoute from "../../investor/pagination-route";
import fetchData from "../../utilities/fetch-data";
import updateStatus from "../../utilities/updateStatus";
import getSearchParams from "../../utilities/getSearchParams";
import Check from "../../utilities/check-isExist";
import { PermissionContext } from "../../../context/permission-context";
import checkPagination from "../../utilities/check-pagination";

const AllGraduate = (props) => {
  // Extract props
  const { match, location, type, moveTo, history } = props;
  // Extract queryParam from url
  const { page, minDate, maxDate, search } = getSearchParams(location.search);
  // Get the current user permission
  const { permission } = useContext(PermissionContext);
  // Initialize Snackbar Hook
  const { enqueueSnackbar } = useSnackbar();
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/apply-for-fund`,
      status: `${type}`,
      isGraduated: true,
    });
    // console.log(dataFromApi);
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [maxDate, minDate, page, search, type]);
  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [location.search]);
  useMemo(() => {
    checkPagination({
      history: history,
      data: data.founder,
      paginations: data.paginations,
    });
  }, [data.founder]);
  return (
    <>
      <Check
        data={data.founder ? true : false}
        children={
          data.founder &&
          data.founder.map((item) => (
            <Card
              key={item._id}
              founder
              button={
                permission.graduate && permission.graduate.investable_company
                  ? "Investable Company"
                  : null
              }
              buttonIcon={<DoubleArrowIcon />}
              data={item}
              popupFounder
              onButtonClickAccepted={() => {
                updateStatus({
                  id: `${item._id}`,
                  status: moveTo[0],
                  isSendEmail: "yes",
                  email: `${item.email}`,
                  greeting: `Dear ${item.fullname}`,
                  message:
                    "Your Company have been selected to become Investable Company",
                  showSnackBar: enqueueSnackbar,
                  userType: "apply-for-fund",
                  fetchAndUpdateView,
                });
              }}
            />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default AllGraduate;
