import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useSnackbar } from "notistack";
import "date-fns";
// Our own written code
// import { PermissionContext } from "../../../context/permission-context";
import Card from "../../card";
import fetchData from "../../utilities/fetch-data";
import PaginationRoute from "../pagination-route";
import updateStatus from "../../utilities/updateStatus";
import getSearchParams from "../../utilities/getSearchParams";
import Check from "../../utilities/check-isExist";
import checkPagination from "../../utilities/check-pagination";
import { NoteContext } from "../../../context/note-context";

const Screening = (props) => {
  // Extract props
  const { match, location, type, moveTo, history } = props;
  // Get note value
  const { value } = useContext(NoteContext);
  // Get the current user permission

  // const { permission } = useContext(PermissionContext);
  // Initialize Snackbar Hook
  const { enqueueSnackbar } = useSnackbar();
  // Extract queryParam from url
  const { page, minDate, maxDate, search, investorTypes, investmentSector } =
    getSearchParams(location.search);
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url and call axios for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
        investorTypes,
        investmentSector,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/investor`,
      status: `${type}`,
    });
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [investmentSector, investorTypes, maxDate, minDate, page, search, type]);
  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [location.search]);
  //Function go back to previous page if don't have any data
  useMemo(() => {
    checkPagination({
      history: history,
      data: data.investors,
      paginations: data.paginations,
    });
  }, [data.investors]);
  return (
    <>
      <Check
        data={data.investors ? true : false}
        children={
          data.investors &&
          data.investors.map((item) => (
            <Card
              acceptButton
              onButtonClickAccepted={() => {
                updateStatus({
                  id: `${item._id}`,
                  status: moveTo[1],
                  isSendEmail: "yes",
                  email: `${item.email}`,
                  note: value,
                  greeting: `Dear ${item.fullname}`,
                  message: "Your request investment is Accepted",
                  showSnackBar: enqueueSnackbar,
                  fetchAndUpdateView,
                });
              }}
              onButtonClickRejected={() => {
                updateStatus({
                  id: `${item._id}`,
                  status: moveTo[0],
                  isSendEmail: "yes",
                  email: `${item.email}`,
                  note: value,
                  greeting: `Dear ${item.fullname}`,
                  message: "Your request investment is Rejected",
                  showSnackBar: enqueueSnackbar,
                  fetchAndUpdateView,
                });
              }}
              key={item._id}
              pending
              data={item}
              popupInvestor
              popup
              buttonClickPopup
            />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default Screening;
