import axios from "axios";
import Slide from "@material-ui/core/Slide";

const updateStatus = async ({
  id = "",
  status = "all",
  email = "test@gmail.com",
  subject = "Your Request Investment Status",
  greeting = "",
  message = "",
  isSendEmail = "no",
  note = " note",
  attachements = "attachements",
  userType = "investor",
  showSnackBar,
  fetchAndUpdateView,
}) => {
  try {
    const data = {
      email,
      status,
      subject,
      greeting,
      message,
      isSendEmail,
      note,
      attachements,
    };
    await axios.patch(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/${userType}/${id}`,
      {
        ...data,
      },
      { withCredentials: true }
    );
    await fetchAndUpdateView();
    showSnackBar(`Move to ${status} success`, {
      variant: "success",
      TransitionComponent: Slide,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  } catch (error) {
    showSnackBar(`Sorry something when wrong`, {
      variant: "error",
      TransitionComponent: Slide,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  }
};

export default updateStatus;
