import axios from "axios";
import { createContext, useState, useEffect, useCallback } from "react";
import doesHttpOnlyCookieExist from "../../component/utilities/doesHttpOnlyCookieExist";

export const UserContext = createContext({
  user: {},
  setUser: () => {},
});

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  // When First Mount fetch all roles and its permission
  // Also fetch the current user role to generate permission accordingly

  const fetchCurrentUserData = useCallback(async () => {
    try {
      // Check if we have cookie or authenticated
      if (doesHttpOnlyCookieExist("authenticated")) {
        // Get Current user's data from backend
        const res = await axios.get(
          `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/me`,
          { withCredentials: true }
        );
        // Set state to provide to children
        setUser(res.data.user);
      }
    } catch (error) {
      // Check if backend return Unauthorized code
      if (error.response.status === 401) {
        window.location.replace("/login");
        return;
      }
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserData();
  }, [fetchCurrentUserData]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
