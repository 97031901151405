import { createContext, useState } from "react";

export const SearchContext = createContext({
  minDate: null,
  setMinDate: () => {},
  maxDate: null,
  setMaxDate: () => {},
  investorTypes: "",
  setInvestorTypes: () => {},
  investmentSector: "",
  setInvestmentSector: () => {},
  search: "",
  setSearch: () => {},
  page: null,
  setPage: () => {},
  clear: false,
  setClear: () => {},
});

const SearchContextProvider = ({ children }) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [investorTypes, setInvestorTypes] = useState("");
  const [investmentSector, setInvestmentSector] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(null);
  const [clear, setClear] = useState(false);

  // When First Mount fetch all roles and its permission
  // Also fetch the current user role to generate permission accordingly

  return (
    <SearchContext.Provider
      value={{
        minDate,
        setMinDate,
        maxDate,
        setMaxDate,
        investorTypes,
        setInvestorTypes,
        investmentSector,
        setInvestmentSector,
        search,
        setSearch,
        page,
        setPage,
        clear,
        setClear,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
