import React, { useCallback, useState } from "react";
import ConfirmationPopCmp from "./confirmation-popup-cmp";

const useConfirmationPopup = () => {
  // Handle Whether to open the cmp or not
  const [isOpen, setIsOpen] = useState(false);
  // State for confirmation cmp props
  const [cmpState, setCmpState] = useState({
    headerText: "Confirmation",
    bodyText: "Are you sure you want to do this ?",
    confirmBtnText: "Continue",
    cancelBtnText: "Cancel",
    onConfirmClick: () => { },
  });

  // Function to trigger the cmp to pop up
  // We can change the text and action of pop cmp by passing the parameter to this function
  const onOpen = ({
    headerText = "Confirmation",
    bodyText = "Are you sure you want to do this ?",
    confirmBtnText = "Continue",
    cancelBtnText = "Cancel",
    onConfirmClick = () => { },
  }) => {
    const newObjForUpdatingState = {
      headerText,
      bodyText,
      confirmBtnText,
      cancelBtnText,
      onConfirmClick,
    };
    setCmpState(newObjForUpdatingState);
    setIsOpen(true);
  };

  // When the state open change then change the cmp to sync with the state we have here
  const ConfirmPopup = useCallback(
    () => (
      <ConfirmationPopCmp
        headerText={cmpState.headerText}
        bodyText={cmpState.bodyText}
        isOpen={isOpen}
        onConfirmClick={cmpState.onConfirmClick}
        onCancelClick={() => setIsOpen(false)}
        confirmBtnText={cmpState.confirmBtnText}
        cancelBtnText={cmpState.cancelBtnText}
      />
    ),
    [cmpState.bodyText, cmpState.cancelBtnText, cmpState.confirmBtnText, cmpState.headerText, cmpState.onConfirmClick, isOpen]
  );

  return {
    ConfirmPopup,
    onOpen,
  };
};

export { useConfirmationPopup };
