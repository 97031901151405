import { Box, Grid, Button } from "@material-ui/core";
import "date-fns";
import React from "react";
import EmployeeCard from "../employee-card";
import { useHistory } from "react-router-dom";
import PaginationRoute from "../../investor/pagination-route";
import TitleHeader from "../../title-header";
import { useContext } from "react";
import { PermissionContext } from "../../../context/permission-context";

const AllEmployee = ({ match, employees, setRender, setGetData, filter }) => {
  const history = useHistory();
  const { permission } = useContext(PermissionContext);
  const handleClick = () => {
    history.push(`${match.url}/new-employee`);
  };

  return (
    <>
      <Box display="flex" marginBottom="20px" justifyContent="space-between">
        <TitleHeader title="Employee" />
        <Box alignSelf="center">
          {permission.registration && permission.registration.create && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClick()}
            >
              New Employee
            </Button>
          )}
        </Box>
      </Box>
      <Grid container spacing={3}>
        {employees.length > 0 ? (
          <>
            {employees.map((item, index) => (
              <Grid key={index} item sm={12} md={6} lg={4}>
                <EmployeeCard
                  data={item}
                  setRender={setRender}
                  setGetData={setGetData}
                />
              </Grid>
            ))}
            {filter && filter.totalPages > 1 && (
              <PaginationRoute
                page={filter.page}
                totalPages={filter.totalPages}
                url={match.url}
              />
            )}
          </>
        ) : (
          <p style={{ fontSize: "3rem", width: "100%" }}>No Results</p>
        )}
      </Grid>
    </>
  );
};

export default AllEmployee;
