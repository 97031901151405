import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import SingleNotification from "./single-notification";

const NotificationList = ({
  notificationData,
  hasNextPage,
  setPage,
  frontendMarkAllAsRead,
  setRender,
}) => {
  const notificationList = notificationData.map((item, index) => {
    const { messageData, recipients, title, sender, createdAt, _id } = item;
    const { isRead, isSeen, userId } = recipients;
    return (
      <React.Fragment key={index}>
        <SingleNotification
          frontendMarkAllAsRead={frontendMarkAllAsRead}
          sender={sender}
          messageData={messageData}
          isRead={isRead}
          isSeen={isSeen}
          userId={userId}
          title={title}
          createdAt={createdAt}
          _id={_id}
          setRender={setRender}
        />
      </React.Fragment>
    );
  });
  return (
    <Grid
      item
      xs={12}
      container
      style={{ maxHeight: "78vh", overflow: "auto", paddingRight: "0.6rem" }}
      id="scrollableDiv"
    >
      {notificationData.length ? (
        <InfiniteScroll
          dataLength={notificationData.length}
          hasMore={hasNextPage}
          next={() => {
            setPage((prev) => prev + 1);
            // console.log("Fetch More");
          }}
          loader={
            <Grid item xs={12} style={{ minHeight: "48px", marginTop: "15px" }}>
              <CircularProgress />
            </Grid>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <Grid item xs={12} style={{ marginTop: "7px" }}>
              <Typography>Showing all notification</Typography>
            </Grid>
          }
        >
          {notificationList}
        </InfiniteScroll>
      ) : (
        <Grid item xs={12} style={{ padding: "0.5rem 0" }}>
          <Typography align="center" variant="h6">
            No notification !
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NotificationList;
