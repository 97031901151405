import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useConfirmationPopup } from "../../hook/confirmation-popup-hook";
import Animation from "../../HOC/animation";
import {
  Typography,
  Paper,
  makeStyles,
  Grid,
  Button,
  TextField,
  Slide,
  Divider,
} from "@material-ui/core";
import SwitchGroup from "./switch";
import DeleteIcon from "@material-ui/icons/Delete";
import TitleHeader from "../title-header";
import { PermissionContext } from "../../context/permission-context";

const useStyle = makeStyles((theme) => ({
  paperStyle: {
    display: "flex",
    // padding: "0 20px 30px 20px",
    // cursor: "pointer",
    borderRadius: "20px",
    marginBottom: "20px",
  },
}));

const Setting = (props) => {
  const { permission } = useContext(PermissionContext);
  // MUI Classes
  const classes = useStyle();
  // Handle the role's data fetched from backend
  const [roleData, setRoleData] = useState([]);
  // Using hook to show snackbar for user-feedback
  const { enqueueSnackbar } = useSnackbar();
  // Using hook to show confirmation popup
  const { ConfirmPopup, onOpen } = useConfirmationPopup();
  // Handle whether we're in creating new role
  const [creating, setCreating] = useState(false);
  // React-hook-form
  const { register, handleSubmit, errors } = useForm();
  // Submit Handler
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles`,
        data,
        { withCredentials: true }
      );
      if (response.status === 201) {
        enqueueSnackbar("Role name you entered already existed !", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          TransitionComponent: Slide,
        });
      } else {
        enqueueSnackbar("Role created successfully!", {
          variant: "success",
          TransitionComponent: Slide,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
      setCreating(false);
      // Update view
      fetchAndUpdateState();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Cannot create role ! Please check and try again !", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        TransitionComponent: Slide,
      });
    }
  };

  // Fetch and update state to refresh the view
  const fetchAndUpdateState = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles`,
      { withCredentials: true }
    );
    setRoleData(data.role);
  };

  // First mount get the initial data
  useEffect(() => {
    fetchAndUpdateState();
  }, []);

  return (
    <>
      <ConfirmPopup />
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <TitleHeader title="Setting" />
        </Grid>
        <Grid item>
          {permission.role && permission.role.create && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCreating(true);
              }}
            >
              Create New
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Create new role form */}
      {creating ? (
        <Animation duration={0.5}>
          <Paper elevation={1} className={classes.paperStyle}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{
                    textAlign: "start",
                    fontWeight: "bold",
                    padding: "20px",
                  }}
                  variant="h5"
                >
                  Create New Role
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ padding: "20px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Typography variant="body1" align="left">
                    Role Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="roleName"
                    fullWidth
                    margin="dense"
                    inputRef={register({
                      required: true,
                    })}
                    error={errors.roleName ? true : false}
                    autoFocus
                  />
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginTop: "0.75rem" }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setCreating(false);
                      }}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Animation>
      ) : (
        <></>
      )}

      {roleData.map((item, index) => {
        const { roleName, features, _id } = item;
        return (
          <Paper elevation={1} className={classes.paperStyle} key={index}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems="center" style={{ padding: "20px" }}>
                  <Grid item xs={true}>
                    <Typography
                      style={{
                        textAlign: "start",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                      variant="h5"
                    >
                      {roleName}
                    </Typography>
                  </Grid>
                  {roleName !== "admin" &&
                    roleName !== "founder" &&
                    roleName !== "investor" &&
                    roleName !== "investor-founder" &&
                    permission.role &&
                    permission.role.delete && (
                      <Grid item>
                        <Button
                          startIcon={<DeleteIcon />}
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            onOpen({
                              headerText: "Deletion Confirmation",
                              onConfirmClick: async () => {
                                // console.log("Deleted : " + _id);
                                const response = await axios.delete(
                                  `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles/${_id}`,
                                  { withCredentials: true }
                                );
                                fetchAndUpdateState();
                                if (response.status === 201) {
                                  enqueueSnackbar(
                                    `Could not deleted role ${roleName.toUpperCase()}! PLease try again !`,
                                    {
                                      variant: "error",
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                      },
                                      TransitionComponent: Slide,
                                    }
                                  );
                                } else {
                                  enqueueSnackbar(
                                    `Role ${roleName.toUpperCase()} deleted successfully!`,
                                    {
                                      variant: "success",
                                      TransitionComponent: Slide,
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                      },
                                    }
                                  );
                                }
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Divider />
              </Grid>
              <Grid container item xs={12} style={{ padding: "20px" }}>
                {features.map((item, index) => {
                  const { featureName, rolePermission } = item;
                  return (
                    <SwitchGroup
                      key={index}
                      _id={_id}
                      rolePermission={rolePermission}
                      featureName={featureName}
                      fetchAndUpdateState={fetchAndUpdateState}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </>
  );
};
export default Setting;
