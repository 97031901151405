import {
  makeStyles,
  TextField,
  IconButton,
  Paper,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { useState, useContext, useMemo, useEffect } from "react";
import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "debounce";
import { SearchContext } from "../../context/search-context";
import getSearchParams from "../utilities/getSearchParams";

const useStyle = makeStyles((theme) => ({
  scroller: {
    "& > span": {
      maxWidth: 0,
    },
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
  },
  gridItem: {
    textAlign: "left",
  },
  dataIcon: {
    "&:hover": {
      backgroundColor: "#fff0",
    },
  },
  buttonStyle: {
    height: "fit-content",
  },
  gridButton: {
    display: "flex",
    alignItems: "center",
    minWidth: "185px",
    justifyContent: "flex-end",
  },
}));

const Filter = ({ company, filterValue, setFilterValue }) => {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const [selectedMinDate, setSelectedMinDate] = useState(null);
  const [SelectedMaxDate, setSelectedMaxDate] = useState(null);
  const {
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    investorTypes,
    setInvestorTypes,
    investmentSector,
    setInvestmentSector,
    setSearch,
    // search,
    // setPage,
    setClear,
  } = useContext(SearchContext);
  const optionsInvestType = [
    "Angel Investor",
    "Angel Group",
    "Corporate Venture Capital",
    "Venture Capital",
    "Private Equity",
    "Family Office",
    "Investment Banker",
    "Accelerator",
    "Others",
  ];
  const optionsInvestmentSector = [
    "Any",
    "Analytics",
    "Construction",
    "Education",
    "Crypto/Blockchain",
    "Energy",
    "Entertainment",
    "Financial Services",
    "Fitness/Wellness",
    "Food/Beverage",
    "Logistics",
    "Gaming",
    "Healthcare",
    "Insurance",
    "Marketplace",
    "Media",
    "Retail",
    "HR/Recruiting/Talent Management",
    "Transportation",
    "Travel/Tourism",
    "Others",
  ];

  const handleMinDateChange = (date, val) => {
    setSelectedMinDate(date);
    setMinDate(getFormattedDate(date));
  };
  const handleMaxDateChange = (date, val) => {
    setSelectedMaxDate(date);
    setMaxDate(getFormattedDate(date));
  };

  // for convert date to format year/month/data
  function getFormattedDate(date) {
    if (date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return year + "-" + month + "-" + day;
    }
    return null;
  }

  //handle when press fitler button
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!history.location.search) {
      history.push(
        `${history.location.pathname}?minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
      );
    } else if (history.location.search) {
      const searchParams = new URLSearchParams(history.location.search);
      const page = parseInt(searchParams.get("page") || "1", 10);
      const search = searchParams.get("search") || "";
      // const page = history.location.search;
      history.push(
        `${history.location.pathname}?page=${page}&search=${search}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
      );
    }
  };
  const handleTypeChange = (event) => {
    setInvestorTypes(event.target.value);
  };
  const handleSectorChange = (event) => {
    setInvestmentSector(event.target.value);
  };

  // search after 0.5s from enter letter
  const handleSearch = debounce((value) => {
    setSearch(value);
    const minDate = getFormattedDate(selectedMinDate);
    const maxDate = getFormattedDate(SelectedMaxDate);
    if (!history.location.search) {
      history.push(`${history.location.pathname}?search=${value}`);
    } else if (history.location.search) {
      const searchParams = new URLSearchParams(history.location.search);
      const page = parseInt(searchParams.get("page") || "1", 10);
      history.push(
        `${history.location.pathname}?page=${page}&search=${value}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
      );
    }
  }, 500);

  // handle when press button clear
  const handleClear = () => {
    setMaxDate(null);
    setMinDate(null);
    setSelectedMaxDate(null);
    setSelectedMinDate(null);
    setInvestmentSector("");
    setInvestorTypes("");
    setSearch("");
    history.push(`${history.location.pathname}`);
  };

  // get data from the url then reassign it to the filter
  useMemo(() => {
    if (location.search) {
      const { minDate, maxDate, search, investorTypes, investmentSector } =
        getSearchParams(location.search);
      const max = maxDate.split("-");
      const min = minDate.split("-");
      if (max.length > 2) {
        setSelectedMaxDate(new Date(max[0], max[1] - 1, max[2]));
      }
      if (min.length > 2) {
        setSelectedMinDate(new Date(min[0], min[1] - 1, min[2]));
      }
      setSearch(search);
      setInvestmentSector(investmentSector);
      setInvestorTypes(investorTypes);
    }
  }, [
    location.search,
    setSearch,
    setInvestmentSector,
    setInvestorTypes,
    setMaxDate,
    setMinDate,
  ]);

  //when component unmount clear data
  useEffect(() => {
    return () => {
      setMaxDate(null);
      setMinDate(null);
      setSelectedMaxDate(null);
      setSelectedMinDate(null);
      setInvestmentSector("");
      setInvestorTypes("");
      setSearch("");
    };
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper
          style={{
            padding: "10px 30px 10px 30px",
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container item style={{ justifyContent: "flex-end" }}>
            <TextField
              variant="outlined"
              margin="dense"
              placeholder="Search..."
              // value={search}
              className={classes.inputBorder}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item className={classes.gridItem}>
              <DatePicker
                name="minDate"
                variant="dialog"
                margin="normal"
                id="date-picker-dialog"
                placeholder="From Date"
                format="dd/MM/yyyy"
                value={selectedMinDate}
                onChange={handleMinDateChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className={classes.dataIcon}>
                        <InsertInvitationIcon color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <DatePicker
                name="maxDate"
                variant="dialog"
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={SelectedMaxDate}
                placeholder="To Date"
                onChange={handleMaxDateChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className={classes.dataIcon}>
                        <InsertInvitationIcon color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {company ? (
              <>
                <Grid xs item style={{ maxWidth: "250px" }}>
                  <FormControl
                    style={{
                      minWidth: "148px",
                      maxWidth: "238px",
                      display: "flex",
                    }}
                    variant="standard"
                    className={classes.formControl}
                  >
                    <InputLabel>Investor Types</InputLabel>
                    <Select value={investorTypes} onChange={handleTypeChange}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {optionsInvestType.map((item) => (
                        <MenuItem key={item} value={`${item}`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs item>
                  <FormControl
                    style={{
                      minWidth: "148px",
                      maxWidth: "238px",
                      display: "flex",
                    }}
                    variant="standard"
                    className={classes.formControl}
                  >
                    <InputLabel>Investment Sector</InputLabel>
                    <Select
                      value={investmentSector}
                      onChange={handleSectorChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {optionsInvestmentSector.map((item) => (
                        <MenuItem key={item} value={`${item}`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item xs={2} className={classes.gridButton}>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginRight: "10px" }}
                  >
                    Filter
                  </Button>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClear()}
                  >
                    Clear
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid container item xs className={classes.gridButton}>
                  <Button
                    className={classes.buttonStyle}
                    style={{ marginRight: "10px" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Filter
                  </Button>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClear()}
                  >
                    Clear
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </MuiPickersUtilsProvider>
    </form>
  );
};

export default Filter;
