import { makeStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useState, useMemo, useContext } from "react";
import "date-fns";
import React from "react";
import Filter from "../filter";
import { Switch, Route, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AllFounder from "./all-founder";
import Screening from "./screening";
import Interview from "./interview";
import Accepted from "./accepted";
import Rejected from "./rejected";
import TitleHeader from "../title-header";
import { SearchContext } from "../../context/search-context";
import { PermissionContext } from "../../context/permission-context";

const useStyle = makeStyles((theme) => ({
  scroller: {
    "& > span": {
      maxWidth: 0,
    },
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },
}));

const Founder = ({ data, match, location }) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();
  const { minDate, maxDate, investorTypes, investmentSector, search } =
    useContext(SearchContext);
  const { permission } = useContext(PermissionContext);
  const type = [
    "all",
    "screening",
    "interview",
    "rejected",
    "accepted",
    "approved",
  ];
  const path = [
    ...(permission.applicant && permission.applicant.view ? [""] : []),
    ...(permission.applicant && permission.applicant.move_to_screening
      ? ["screening"]
      : []),
    ...(permission.applicant && permission.applicant.interview
      ? ["interview"]
      : []),
    ...(permission.applicant && permission.applicant.rejected
      ? ["rejected"]
      : []),
    ...(permission.applicant && permission.applicant.accepted
      ? ["accepted"]
      : []),
  ];
  const tabLabel = [
    ...(permission.applicant && permission.applicant.view
      ? ["All Applicant"]
      : []),
    ...(permission.applicant && permission.applicant.move_to_screening
      ? ["Screening"]
      : []),
    ...(permission.applicant && permission.applicant.interview
      ? ["Interview"]
      : []),
    ...(permission.applicant && permission.applicant.rejected
      ? ["Rejected"]
      : []),
    ...(permission.applicant && permission.applicant.accepted
      ? ["Accepted"]
      : []),
  ];
  const handleChange = () => {
    // console.log(pathname);
    if (pathname == "/applicant") {
      setValue(path[0]);
    } else {
      path.map((value) => {
        if (pathname.includes(value)) {
          setValue(value);
        }
      });
    }
  };
  useMemo(() => handleChange(), [pathname]);
  return (
    <>
      <TitleHeader title="Applicant" />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ scroller: classes.scroller }}
      >
        {tabLabel.map((item, index) => (
          <Tab
            key={index}
            component={Link}
            to={
              location.search
                ? `${match.url}/${path[index]}?search=${search}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
                : path[index] === ""
                ? `${match.url}`
                : `${match.url}/${path[index]}`
            }
            disableRipple
            label={item}
            value={path[index]}
          />
        ))}
      </Tabs>
      <Filter />
      <div className="tabs">
        <Switch>
          <Route
            path={`${match.url}`}
            exact
            render={(props) => (
              <AllFounder
                {...props}
                type={type[0]}
                moveTo={[type[1], type[3]]}
              />
            )}
          />
          {permission.applicant && permission.applicant.move_to_screening && (
            <Route
              path={`${match.url}/screening`}
              exact
              render={(props) => (
                <Screening
                  {...props}
                  type={type[1]}
                  moveTo={[type[2], type[3]]}
                />
              )}
            />
          )}

          {permission.applicant && permission.applicant.interview && (
            <Route
              path={`${match.url}/interview`}
              exact
              render={(props) => (
                <Interview
                  {...props}
                  type={type[2]}
                  moveTo={[type[3], type[4]]}
                />
              )}
            />
          )}
          {permission.applicant && permission.applicant.rejected && (
            <Route
              path={`${match.url}/rejected`}
              exact
              render={(props) => <Rejected {...props} type={type[3]} />}
            />
          )}
          {console.log(permission.applicant)}
          {permission.applicant && permission.applicant.accepted && (
            <Route
              path={`${match.url}/accepted`}
              exact
              render={(props) => (
                <Accepted {...props} type={type[4]} moveTo={[type[5]]} />
              )}
            />
          )}
        </Switch>
      </div>
    </>
  );
};

export default Founder;
