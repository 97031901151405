import { createMuiTheme } from "@material-ui/core";

const primary = "#172E76";
const light = "#0b267a14";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      light: light,
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow:
          "2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)",
      },
      elevation2: {
        boxShadow:
          "2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 20px 0px rgb(0 0 0 / 10%)",
      },
    },
  },
});

export default theme;
