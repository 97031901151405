import {
  Box,
  Grid,
  makeStyles,
  Typography,
  InputAdornment,
  Button,
  FormControl,
  TextField,
  Paper,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useEffect, useRef, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import Popup from "./pop-up";
import CircularProgress from "@material-ui/core/CircularProgress";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import TitleHeader from "../../title-header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyle = makeStyles((theme) => ({
  scroller: {
    "& > span": {
      maxWidth: 0,
    },
  },
  inputBorder: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  inputStyle: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: "5px",
    padding: "5px",
    border: " 1px solid #8080806b",
  },
  paddingInput: {
    padding: "12px",
  },
  text: {
    margin: "10px 0",
  },
  fieldSet: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },
  icon: {
    position: "absolute",
    top: "70%",
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "50%",
  },
  iconCancel: {
    position: "absolute",
    top: "10%",
    left: "5%",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
  },
  imageRequired: {
    position: "absolute",
    top: "45%",
    left: "40%",
    backgroundColor: "none",
    color: "white",
    // borderRadius: "50%",
  },
}));

const CreateNew = ({ getData, setRender, update, roleData }) => {
  const classes = useStyle();
  const { register, handleSubmit, control, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMesssage] = useState("");
  const [image, setImage] = useState(null);
  const [exist, setExist] = useState(false);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const onSubmit = async (dataObj) => {
    console.log(getData._id, dataObj);
    setLoading(true);
    if (image) {
      const avatar = await Upload(image);
      dataObj.avatar = avatar;
    }
    // create new employee
    if (typeof getData !== "object") {
      axios
        .post(
          `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/users`,
          {
            ...dataObj,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          setOpen(true);
          setMesssage(res.data.message);
          if (res.data.user) {
            setExist(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setOpen(true);
          setExist(false);
          setMesssage("Sorry Something when wrong");
          console.log(err);
        });
      //update employee
    } else if (typeof getData === "object") {
      axios
        .put(
          `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/users/${getData._id}`,
          {
            ...dataObj,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          setOpen(true);
          setMesssage(res.data.message);
          setExist(true);
          setRender((pre) => !pre);
        })
        .catch((err) => {
          setLoading(false);
          setOpen(true);
          setExist(false);
          setMesssage("Sorry Something when wrong");
          console.log(err);
        });
    }
  };
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleClickRemove = () => {
    setPreview(null);
    setImage(null);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      if (getData.avatar && getData.avatar.includes(".")) {
        setPreview(`${getData.avatarUrl}`);
      } else {
        setPreview(null);
      }
    }
  }, [image]);

  const uploadFile = async ({ url, file, type, Key }) => {
    let currentFile = file;

    try {
      const data = await axios.put(url, currentFile, {
        headers: {
          "Content-Type": type,
        },
      });
      console.log(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const Upload = async (formData) => {
    const ContentTypes = [image.type];
    let avatar;
    try {
      //get uploadUrl
      const { data } = await axios.post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/aws-s3/upload/multiple`,
        { ContentTypes, folder: "" }
      );
      if (Array.isArray(data)) {
        avatar = await Promise.all(
          data.map(async ({ uploadURL, Key, type }, index) => {
            try {
              // use uploadUrl to upload file to s3

              await uploadFile({
                url: uploadURL,
                file: image,
                type,
                Key,
              });
              return Key;
            } catch (err) {
              console.log(err);
            }
          })
        );
      }
    } catch (err) {
      console.log("this is ", err);
    }
    formData.avatar = avatar[0];
    return avatar[0];
  };
  let name = ["", ""];
  if (getData.fullname) {
    name = getData.fullname.split(" ");
  }
  return (
    <>
      <TitleHeader title={update ? `Edit` : `Create Employee`} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper style={{ padding: "25px" }}>
          <Grid container>
            <Grid item md={12} lg={4}>
              <Box
                width="300px"
                height="300px"
                border="1px solid gray"
                borderRadius="50%"
                position="relative"
              >
                {preview ? (
                  <img
                    src={preview}
                    style={{
                      objectFit: "cover",
                      width: "inherit",
                      height: "inherit",
                      borderRadius: "inherit",
                    }}
                  />
                ) : (
                  <img
                    src="/default.png"
                    style={{
                      objectFit: "cover",
                      width: "inherit",
                      height: "inherit",
                      borderRadius: "inherit",
                    }}
                  />
                )}
                <div className={classes.icon}>
                  <input
                    ref={inputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    onChange={(e) => (
                      //change target value to prevent when remove and add same image onChange not trigger
                      setImage(e.target.files[0]), (e.target.value = "")
                    )}
                  />
                  <label>
                    <IconButton
                      onClick={handleClick}
                      color="inherit"
                      component="span"
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
                {image && (
                  <div className={classes.iconCancel}>
                    <label>
                      <IconButton
                        onClick={() => handleClickRemove()}
                        component="span"
                        style={{ padding: "6px" }}
                      >
                        <CloseIcon style={{ color: "white" }} />
                      </IconButton>
                    </label>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid
              item
              // xs={8}
              md={12}
              lg={8}
              container
              spacing={2}
              style={{ textAlign: "start" }}
            >
              <Grid item xs={6}>
                <Typography color="textSecondary">FirstName *</Typography>
                <TextField
                  required
                  className={classes.text}
                  variant="outlined"
                  name="firstname"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.firstname ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.fullname && name[0]}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">LastName *</Typography>
                <TextField
                  required
                  className={classes.text}
                  name="lastname"
                  variant="outlined"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.lastname ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.fullname && name[1]}
                ></TextField>
              </Grid>
              <Grid xs={6} item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      getData.dateOfBirth ? getData.dateOfBirth : null
                    }
                    as={
                      <DatePicker
                        disableFuture
                        openTo="year"
                        views={["year", "month", "date"]}
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-dialog"
                        required={true}
                        label="Date of birth"
                        format="dd/MM/yyyy"
                        error={errors.dateOfBirth ? true : false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton className={classes.dataIcon}>
                                <InsertInvitationIcon color="primary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormLabel
                    required
                    error={errors.gender ? true : false}
                    component="legend"
                    // classes={{ root: classes.labelRoot }}
                  >
                    Gender
                  </FormLabel>
                  <Controller
                    as={
                      <RadioGroup
                        style={{ display: "block" }}
                        aria-label="gender"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio color="primary" />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio color="primary" />}
                          label="Male"
                        />
                      </RadioGroup>
                    }
                    name="gender"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      getData.gender && getData.gender.toLowerCase()
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">Phone Number *</Typography>
                <TextField
                  required
                  className={classes.text}
                  variant="outlined"
                  name="phone"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                      message: "Invalid Value",
                    },
                  })}
                  error={errors.phone ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.phone && getData.phone}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">Email *</Typography>
                <TextField
                  required
                  className={classes.text}
                  variant="outlined"
                  name="email"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: true,
                    pattern: {
                      value:
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  error={errors.email ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.email && getData.email}
                ></TextField>
              </Grid>
              {Array.isArray(roleData) && (
                <Grid item xs={12}>
                  <Typography color="textSecondary">RoleName</Typography>
                  <FormControl
                    className={classes.text}
                    variant="outlined"
                    fullWidth
                    error={errors.roleName ? true : false}
                  >
                    <Controller
                      as={
                        <Select
                          classes={{ outlined: classes.paddingInput }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                        >
                          {roleData.map((roleName) => (
                            <MenuItem key={roleName} value={roleName}>
                              {roleName}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="roleName"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={getData.roleName && getData.roleName}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography color="textSecondary">Linkin </Typography>
                <TextField
                  required
                  className={classes.text}
                  variant="outlined"
                  name="linkedIn"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: false,
                  })}
                  error={errors.linkedIn ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.linkedIn && getData.linkedIn}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">Address *</Typography>
                <TextField
                  required
                  className={classes.text}
                  variant="outlined"
                  name="address"
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.address ? true : false}
                  InputProps={{ classes: { input: classes.paddingInput } }}
                  defaultValue={getData.address && getData.address}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              textAlign: "center",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ position: "relative" }}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
              >
                {update ? "save" : "submit"}
              </Button>
              {loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </Paper>
      </form>
      <Popup open={open} exist={exist} setOpen={setOpen} message={message} />
    </>
  );
};

export default CreateNew;
