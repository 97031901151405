import { Grid, Button, Box, makeStyles, Typography } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import formatDate from "../../utilities/formatDate";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";

const useStyle = makeStyles((theme) => ({
  GridTemplate: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1.5fr 1fr 1.5fr",
    columnGap: "10px",
    rowGap: "10px",
  },
  typographyGrid: {
    marginBottom: "5x",
    textAlign: "left",
  },
  cricle: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    display: "inline-block",
    alignSelf: "center",
  },
  typographyGridTitle: {
    fontWeight: "bold",
  },
  paddingGrid: {
    padding: "10px 0",
  },
  linkStyle: {
    textDecoration: "none",
    color: "#496cd6",
  },
}));

const CardDecription = ({
  setOpen,
  founder,
  Accepted,
  Rejected,
  pending,
  acceptButton,
  button = "",
  buttonIcon = <ThumbUpAltOutlinedIcon />,
  data,
  onButtonClickAccepted,
  onButtonClickRejected,
  buttonClickPopup,
  buttonOpen,
  setButtonOpen,
  buttonClick,
  setButtonClick,
  rejectedButton,
  rejectedButtonIcon,
}) => {
  const classes = useStyle();
  const [confirm, setConfirm] = useState(false);
  let condition = "";
  let conditionColor = "";
  let cursor = "pointer";
  if (Accepted) {
    condition = "Accepted";
    conditionColor = "green";
  } else if (Rejected) {
    condition = "Rejected";
    conditionColor = "red";
  } else if (pending) {
    condition = "Pending";
    conditionColor = "gold";
  }

  if (!buttonClickPopup) {
    cursor = "pointer";
  }
  const handleClick = () => {
    if (setOpen) {
      setOpen(true);
    }
  };
  const handleConfirmYes = () => {
    if (buttonClickPopup) {
      setConfirm(false);
      setButtonOpen(true);
    } else {
      if (buttonClick) {
        setConfirm(false);
        onButtonClickAccepted();
      } else {
        setConfirm(false);
        onButtonClickRejected();
      }
    }
  };
  const handleConfirmNo = () => {
    setConfirm(false);
  };
  // const handleAc
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={8}
          onClick={() => handleClick()}
          style={{ cursor: `${cursor}` }}
          container
          //   spacing={2}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                display: "block",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
              variant="h6"
            >
              {data.companyName ? data.companyName : data.company.name}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={`${classes.GridTemplate} ${classes.paddingGrid}`}
          >
            <Typography
              className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
            >
              {founder ? "Applicant Name:" : "Investor Name:"}
            </Typography>
            <Typography className={classes.typographyGrid}>
              {data.fullname}
            </Typography>
            <Typography
              className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
            >
              Position:
            </Typography>
            <Typography className={classes.typographyGrid}>
              {founder ? "Founder" : data.jobTitle}
            </Typography>
            <Typography
              className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
            >
              Phone Number:
            </Typography>
            <Typography className={classes.typographyGrid}>
              <a className={classes.linkStyle} href={`tel:${data.phone}`}>
                {data.phone}
              </a>
            </Typography>

            {founder ? (
              <>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  Sector:
                </Typography>
                <Typography className={classes.typographyGrid}>
                  {data.company.type}
                </Typography>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  Email:
                </Typography>

                <Typography className={classes.typographyGrid}>
                  <a
                    className={classes.linkStyle}
                    href={`mailto:${data.email}`}
                  >
                    {data.email}
                  </a>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  investorTypes:
                </Typography>
                <Typography className={classes.typographyGrid}>
                  {data.investorTypes}
                </Typography>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  Email:
                </Typography>
                <Typography className={classes.typographyGrid}>
                  <a
                    className={classes.linkStyle}
                    href={`mailto:${data.email}`}
                  >
                    {data.email}
                  </a>
                </Typography>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  Sector:
                </Typography>
                <Typography className={classes.typographyGrid}>
                  {data.investmentSector}
                </Typography>
              </>
            )}
          </Grid>
          {!founder && (
            <>
              <Grid item xs className={classes.paddingGrid}>
                <Typography
                  className={`${classes.typographyGrid} ${classes.typographyGridTitle}`}
                >
                  Target Investment Amount:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  className={classes.typographyGrid}
                  style={{ padding: "10px 0" }}
                >
                  {data.targetInvestmentAmount}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <span
                className={classes.cricle}
                style={{ backgroundColor: `${conditionColor}` }}
              />
              <Typography
                style={{ margin: "0 5px", color: `${conditionColor}` }}
              >
                {condition}
              </Typography>
            </Box>
            <Box display="flex">
              <ScheduleIcon
                color="primary"
                style={{ margin: "0 5px", alignSelf: "center" }}
                fontSize="small"
              />
              <Typography>{formatDate(data.createdAt)}</Typography>
            </Box>
          </Box>
          {acceptButton && (
            <Box textAlign="end">
              <Button
                style={{
                  color: "green",
                  borderColor: "green",
                  borderRadius: "50px",
                  width: "45%",
                  marginRight: "5px",
                }}
                onClick={() => {
                  setConfirm(true);
                  setButtonClick(true);
                }}
                variant="outlined"
              >
                Accept
              </Button>
              <Button
                style={{
                  borderRadius: "50px",
                  width: "45%",
                  color: "red",
                  borderColor: "red",
                }}
                variant="outlined"
                onClick={() => {
                  setConfirm(true);
                  setButtonClick(false);
                }}
              >
                Reject
              </Button>
            </Box>
          )}
          {button && (
            <Box textAlign="end">
              <Button
                style={{
                  borderRadius: "50px",
                  alignSelf: "flex-end",
                  margin: 5,
                }}
                variant="outlined"
                startIcon={buttonIcon}
                color="primary"
                onClick={() => {
                  setConfirm(true);
                  setButtonClick(true);
                }}
              >
                {button}
              </Button>
              {rejectedButton && (
                <Button
                  style={{
                    borderRadius: "50px",
                    alignSelf: "flex-end",
                    margin: 5,
                    color: "red",
                    borderColor: "red",
                  }}
                  variant="outlined"
                  startIcon={rejectedButtonIcon || null}
                  color="primary"
                  onClick={() => {
                    setConfirm(true);
                    setButtonClick(false);
                  }}
                >
                  {rejectedButton}
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog open={confirm} maxWidth="xs" fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h5" color="primary">
            Confirm
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            Are you Sure you want to do this, This action can't be undone ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmYes()} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => handleConfirmNo()} color="secondary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardDecription;
