import { Box } from "@material-ui/core";
import Profile from "./profile";
import Notification from "./notification";
// Return Cmp only when user authenticated
import { useContext } from "react";
import { UserContext } from "../../context/user-context";

const ProfileHeader = () => {
  // User context
  const { user } = useContext(UserContext);
  return user.id ? (
    <Box display="flex" justifyContent="flex-end">
      <Notification />
      <Profile />
    </Box>
  ) : (
    <></>
  );
};

export default ProfileHeader;
