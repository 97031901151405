import axios from "axios";

const fetchData = async ({
  filters = {
    minDate: "",
    maxDate: "",
    search: "",
    investmentSector: "",
    investorTypes: "",
    page: "",
    perPage: 10,
    companyCategory: "",
  },
  url,
  status = "",
  isGraduated = "",
}) => {
  try {
    const { data } = await axios.get(
      `${url}?minDate=${
        filters.minDate !== "null" ? filters.minDate : ""
      }&maxDate=${filters.maxDate !== "null" ? filters.maxDate : ""}&search=${
        filters.search
      }&investmentSector=${filters.investmentSector || ""}&investorTypes=${
        filters.investorTypes || ""
      }&status=${status || ""}&isGraduated=${isGraduated}&page=${
        filters.page
      }&perPage=${filters.perPage || 10}`,
      { withCredentials: true }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export default fetchData;
