import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const Popup = (props) => {
  const { open, exist, setOpen, message } = props;
  const history = useHistory();
  const handleRetureHome = () => {
    history.replace(`/employee`);
  };
  const handleError = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle disableTypography>
          <Typography variant="h5" color={exist ? "primary" : "error"}>
            {exist ? "Successful" : "Error"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => (exist ? handleRetureHome() : handleError())}
            color="primary"
            autoFocus
          >
            {exist ? "OK" : "Try Again"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
