import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "../../../context/search-context";

const PaginationRoute = (props) => {
  const { page, totalPages, url } = props;
  const location = useLocation();

  const { minDate, maxDate, investorTypes, investmentSector, search } =
    useContext(SearchContext);
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100% " }}>
      <Pagination
        page={page}
        count={totalPages}
        color="primary"
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`${url}${
              location.search.length > 0
                ? `?page=${item.page}&search=${search}&minDate=${minDate}&maxDate=${maxDate}&investmentSector=${investmentSector}&investorTypes=${investorTypes}`
                : `?page=${item.page}`
            }`}
            {...item}
          />
        )}
      />
    </div>
  );
};

export default PaginationRoute;
