import React, { useContext } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  Typography,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { PermissionContext } from "../../../context/permission-context";

const useStyles = makeStyles((theme) => ({
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const SwitchGroup = ({
  featureName,
  rolePermission,
  _id,
  fetchAndUpdateState,
}) => {
  // MUI Classes
  const classes = useStyles();
  // Using hook to show snackbar for user-feedback
  const { enqueueSnackbar } = useSnackbar();
  const { permission } = useContext(PermissionContext);
  return (
    <Grid item xs={12} md={6} lg={4} style={{ overflow: "hidden" }}>
      <Typography
        variant="h6"
        noWrap
        style={{ textAlign: "start", textTransform: "capitalize" }}
      >
        {featureName === "registration" ? "employee" : featureName}
      </Typography>
      <FormControl style={{ width: "100%" }} component="fieldset">
        <FormGroup style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
          {rolePermission.map((item, index) => {
            const { permissionName, boolean } = item;
            return (
              <FormControlLabel
                key={index}
                classes={{
                  label: classes.label,
                }}
                control={
                  <Switch
                    checked={boolean}
                    disabled={
                      permission.role && permission.role.update ? false : true
                    }
                    onChange={async (e) => {
                      try {
                        await axios.put(
                          `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/roles/${_id}`,
                          {
                            boolean: e.target.checked,
                            featureName,
                            permissionName,
                          },
                          { withCredentials: true }
                        );
                        fetchAndUpdateState();
                        enqueueSnackbar(
                          `Permission to ${permissionName.toUpperCase()} in ${featureName.toUpperCase()} has been updated ! `,
                          {
                            variant: "success",
                            TransitionComponent: Slide,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                          }
                        );
                      } catch (error) {
                        console.log(error);
                        enqueueSnackbar(
                          `Permission to ${permissionName.toUpperCase()} in ${featureName.toUpperCase()} could not update ! Please try again !`,
                          {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            TransitionComponent: Slide,
                          }
                        );
                      }
                    }}
                    name={permissionName}
                    color="primary"
                  />
                }
                label={permissionName}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
export default SwitchGroup;
