/* eslint-disable no-control-regex */
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import Popup from "../pop-up";

// import IsLogin from "../utilities/useLogin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imageBox: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  containter: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    "-webkit-tap-highlight-color": "transparent",
    zIndex: "1201",
    display: "flex",
  },
  image: {
    position: "absolute",
    zIndex: -1,
    objectFit: "cover",
    height: "100%",
    width: "100%",
    opacity: 0.5,
  },
}));

const ForgetPassword = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMassage] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (userData) => {
    axios
      .post(
        `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/auth/request-password`,
        { ...userData },
        { withCredentials: true }
      )
      .then((res) => {
        setOpen(true);
        setError(false);
        setMassage(res.data.message);
        // console.log("forget password", res);
        // setReset(true);
      })
      .catch((err) => {
        setOpen(true);
        setError(true);
        setMassage("Sorry something when wrong");
        console.log(err);
      });
    // console.log("onsubmit", userData);
  };

  return (
    <div className={classes.containter}>
      <img src="/back.jpg" className={classes.image} alt="Background" />
      <Popup open={open} setOpen={setOpen} error={error} message={message} />
      <Container component="main" maxWidth="sm">
        <Paper style={{ padding: "30px" }}>
          <Box width="100%" height="250px" className={classes.imageBox}>
            <img src="/logo.jpeg" alt="Logo" />
          </Box>
          <Typography variant="h5">Forget your password ?</Typography>
          <Typography>
            Don't worry reset your passoword is easy, Just type in the email you
            registered to FYT
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter a your email address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: true,
                pattern: {
                  value:
                    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: "Entered value does not match email format",
                },
              })}
              error={errors.email ? true : false}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              SEND
            </Button>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              Do you remember the password ?
              <Link
                href="/login"
                variant="body2"
                style={{ paddingLeft: "10px" }}
              >
                Try to Login
              </Link>
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
};
export default ForgetPassword;
