import { Drawer, Box, makeStyles, useTheme } from "@material-ui/core";
import ListItemSideBar from "./list-item-sideBar";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const drawerWidth = 300;

const useStyle = makeStyles((theme) => ({
  imageBox: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    padding: "20px 0 10px 0",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: "65px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    position: "absolute",
    right: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const SideBar = () => {
  const classes = useStyle();
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  useMemo(() => {
    if (matchesSM && open) {
      setOpen(false);
    } else if (!matchesSM && !open) {
      setOpen(true);
    }
  }, [matchesSM]);
  const handleDrawer = () => {
    setOpen((pre) => !pre);
  };
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawer}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Box
        height="80px"
        width={drawerWidth}
        className={classes.imageBox}
        component={Link}
        to=""
      >
        <img src="/logo.jpeg" className={classes.image} alt="logo" />
      </Box>
      <ListItemSideBar />
    </Drawer>
  );
};

export default SideBar;
