import { useState, useEffect, useContext, useCallback, useRef } from "react";
import "date-fns";
// Our own written code
import Card from "../../card";
import fetchData from "../../utilities/fetch-data";
import PaginationRoute from "../../investor/pagination-route";
import getSearchParams from "../../utilities/getSearchParams";
import Check from "../../utilities/check-isExist";
import { PermissionContext } from "../../../context/permission-context";

const InvestableCompany = (props) => {
  // Extract props
  const {
    match,
    location,
    type,
    // moveTo
  } = props;
  const linkRef = useRef();
  // Extract queryParam from url
  const { page, minDate, maxDate, search } = getSearchParams(location.search);
  // Get the current user permission
  const { permission } = useContext(PermissionContext);
  // Handle data which generate the view
  const [data, setData] = useState([]);
  // Function to call when we want to render or rerender the view
  const fetchAndUpdateView = useCallback(async () => {
    // Call a function that generate url for us
    const dataFromApi = await fetchData({
      filters: {
        page: page,
        minDate: minDate,
        maxDate: maxDate,
        search: search,
      },
      url: `${process.env.REACT_APP_DASHBOARD_API_URL}/api/v1/apply-for-fund`,
      status: `${type}`,
      isGraduated: true,
    });
    // console.log(dataFromApi);
    // Set the data got from request to update view
    setData(dataFromApi);
  }, [maxDate, minDate, page, search, type]);
  // First Mount generate the view
  useEffect(() => {
    fetchAndUpdateView();
  }, [fetchAndUpdateView]);
  return (
    <>
      <Check
        data={data.founder ? true : false}
        children={
          data.founder &&
          data.founder.map((item, index) => (
            <Card
              founder
              key={item._id}
              data={item}
              button={
                permission.graduate && permission.graduate.interest
                  ? "Interest"
                  : null
              }
              // buttonClickPopup={true}
              onButtonClickAccepted={() =>
                window.open(`mailto:${data.founder[index].email}`)
              }
              popupFounder
              // popupEmail
            />
          ))
        }
      />
      {data.paginations && data.paginations.totalPages > 1 && (
        <PaginationRoute
          page={page}
          totalPages={data.paginations.totalPages}
          url={match.url}
        />
      )}
    </>
  );
};

export default InvestableCompany;
