import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Typography, makeStyles, Grid, Box } from "@material-ui/core";
import CardDecription from "../card-decription";
import axios from "axios";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import path from "path";

const useStyle = makeStyles((theme) => ({
  GridTemplate: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
  },
  typographyGrid: {
    marginBottom: "25px",
    textAlign: "left",
  },
  cricle: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    display: "inline-block",
    alignSelf: "center",
  },
  boxTitle: {
    backgroundColor: theme.palette.primary.main,
    padding: "0 20px",
    borderRadius: "50px",
    margin: "20px 0",
  },
  imageIcon: {
    fontSize: "3rem",
  },
  downloadBox: {
    height: "50px",
    width: "170px",
    display: "flex",
    padding: "10px 25px 10px 10px",
    borderRadius: "7px",
    position: "relative",
    boxShadow: "3px 5px 5px 0px rgb(0 0 0 / 10%)",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      boxShadow: "3px 5px 20px 0px rgb(0 0 0 / 20%)",
    },
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PopupFounder(props) {
  const classes = useStyle();
  const { open, setOpen, data, card } = props;
  const { idea, progress, about, people } = data;
  const [files, setFiles] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const getFile = (url, name, newName) => {
    axios({
      url: `${url}`,
      method: "GET",
      // headers: headers,
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${newName ? `${newName}${path.extname(name)}` : name}`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) =>
        console.log(err, "src/component/card/pop-up-founder ,line:107"),
      );
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose()}
        aria-labelledby="customized-diamslog-title"
        open={open}
      >
        <DialogContent dividers style={{ padding: "30px" }}>
          <Grid container>
            {card}
            <Grid item xs>
              <Box display="inline-block" className={classes.boxTitle}>
                <Typography style={{ color: "white" }}>Idea</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How did you come up with this idea?
                </Typography>
                <Typography>{idea.bussinessIdea}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Who are your core customers?
                </Typography>
                <Typography>{idea.customerTarget}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  What are your core marketing methods?
                </Typography>
                <Typography>{idea.marketingMethod}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  What are the major threats to your business today?
                </Typography>
                <Typography>{idea.majorObstacles}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  What are your very best opportunities for the future?
                </Typography>
                <Typography>{idea.visionOpportunity}</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Box display="inline-block" className={classes.boxTitle}>
                <Typography style={{ color: "white" }}>Teams</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How many founders are on the team?{" "}
                </Typography>
                <Typography>{people.numberOfFounders}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How many employees work full time in your company?
                </Typography>
                <Typography>{people.numberOfFullTimeEmployees}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How many founders work full time in the company?
                </Typography>
                <Typography>{people.numberOfFullTimeFounders}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  What are the skills or core competencies of each founder?
                </Typography>
                <Typography>{people.eachFounderSkill.join(", ")}</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Box display="inline-block" className={classes.boxTitle}>
                <Typography style={{ color: "white" }}>Progress</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How long have you worked for this project?
                </Typography>
                <Typography>{progress.numberOfYear}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Are people using your product or services?
                </Typography>
                <Typography>{progress.statusOfProduct}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Do you have revenue? </Typography>
                <Typography>
                  {progress.isGeneratingRevenue ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Box display="inline-block" className={classes.boxTitle}>
                <Typography style={{ color: "white" }}>About FYT</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  What are your main expectations from the program?
                </Typography>
                <Typography>{about.expectations}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  How do you know about FYT101?
                </Typography>
                <Typography>{about.knowFTYBy}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {data.attachmentUrls?.length > 0 && (
            <>
              <Grid item xs>
                <Box display="inline-block" className={classes.boxTitle}>
                  <Typography style={{ color: "white" }}>Attachment</Typography>
                </Box>
              </Grid>
              <Grid item></Grid>
              <Grid item spacing={2} container>
                {data.attachmentUrls.map((attachment, index) => (
                  <Grid
                    item
                    key={index}
                    // display="flex"
                  >
                    <Box
                      onClick={() =>
                        getFile(
                          attachment,
                          data.about.attachments[index],
                          data.company?.name &&
                            `${data.company?.name} (pitch_deck_${index})`,
                        )
                      }
                      className={classes.downloadBox}
                    >
                      <InsertDriveFileIcon className={classes.imageIcon} />
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {`Download Pitch Deck ${index}`}
                      </span>
                      <GetAppIcon
                        style={{
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          marginBottom: "5px",
                          marginRight: "5px",
                          transform: "translate(0, -60%)",
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {data.founderStatus &&
            data.founderStatus[data.founderStatus.length - 1].note &&
            data.founderStatus[data.founderStatus.length - 1].note.length >
              0 && (
              <Grid item xs>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Note:
                </Typography>
                <Typography>
                  {data.founderStatus[data.founderStatus.length - 1].note}
                </Typography>
              </Grid>
            )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
